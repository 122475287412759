import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import TasksList from "../partials/components/TasksList";

function Task() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const userToken = localStorage.getItem("token");
  if (userToken == null) {
    window.location = "/login";
  }
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Tasks list */}
            <TasksList />

            <div className="grid grid-cols-12 gap-6">
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Task;
