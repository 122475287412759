import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto"; // Asegúrate de importar Chart.js
import Tooltip from "../components/Tooltip";

const DashboardCard09 = ({ chartData }) => {
  const [viewMode, setViewMode] = useState("day"); // Estado para controlar si mostramos por día o por mes
  const [processedData, setProcessedData] = useState(chartData); // Datos procesados según la selección

  useEffect(() => {
    // Función para agrupar los datos por mes
    const groupDataByMonth = (data) => {
      const groupedData = data.labels.reduce((acc, label, index) => {
        const month = new Date(label).toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        if (!acc[month]) acc[month] = 0;
        const value = data.datasets[0].data[index];
        acc[month] += isNaN(value) ? 0 : value;
        return acc;
      }, {});
      return {
        labels: Object.keys(groupedData),
        datasets: [
          {
            label: "Importes Recuperados",
            data: Object.values(groupedData),
            backgroundColor: "#3b82f6", // Azul principal
            hoverBackgroundColor: "#2563eb", // Azul hover
            borderRadius: 8,
            borderWidth: 0,
            barPercentage: 0.6,
            categoryPercentage: 0.7,
          },
        ],
      };
    };

    // Procesar los datos según la vista seleccionada
    if (viewMode === "month") {
      setProcessedData(groupDataByMonth(chartData));
    } else {
      setProcessedData({
        ...chartData,
        datasets: [{
          ...chartData.datasets[0],
          data: chartData.datasets[0].data.map(value => isNaN(value) ? 0 : value),
          backgroundColor: "#3b82f6",
          hoverBackgroundColor: "#2563eb",
          borderRadius: 8,
          borderWidth: 0,
          barPercentage: 0.6,
          categoryPercentage: 0.7,
        }]
      });
    }
  }, [viewMode, chartData]);

  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "#1e40af",
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        borderColor: "#3b82f6",
        borderWidth: 1,
        padding: 12,
        cornerRadius: 8,
        displayColors: false,
        titleFont: {
          size: 14,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            if (isNaN(value) || value === null) {
              return '0 €';
            }
            return `${Number(value).toLocaleString('es-ES')} €`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#64748b",
          font: {
            size: 12,
          },
          maxRotation: 45,
          minRotation: 45,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#e2e8f0",
          drawBorder: false,
          borderDash: [5, 5],
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => {
            if (isNaN(value) || value === null) {
              return '0 €';
            }
            return `${Number(value).toLocaleString('es-ES')} €`;
          },
          color: "#64748b",
          font: {
            size: 12,
          },
          padding: 10,
        },
        border: {
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    animation: {
      duration: 500,
    },
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-sm rounded-xl border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
          <div className="flex items-center space-x-2">
            <h2 className="font-semibold text-slate-800">Importes Recuperados</h2>
            <Tooltip className="ml-2">
              <div className="text-sm">
                Representa los importes recuperados por {viewMode === "day" ? "día" : "mes"}.
              </div>
            </Tooltip>
          </div>
          <div className="flex items-center space-x-2">
            <label className="text-sm font-medium text-slate-600" htmlFor="viewModeSelector">
              Ver por:
            </label>
            <select
              id="viewModeSelector"
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
              className="form-select text-sm border-slate-200 hover:border-slate-300 focus:border-blue-500 focus:ring-blue-500 rounded-lg py-1.5 pl-3 pr-8"
            >
              <option value="day">Por Día</option>
              <option value="month">Por Mes</option>
            </select>
          </div>
        </div>
      </header>

      <div className="flex-grow px-5 pt-3 pb-5">
        <div style={{ height: "300px" }}>
          {processedData && processedData.labels.length > 0 ? (
            <Bar data={processedData} options={barChartOptions} />
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-slate-500 text-sm">No hay datos disponibles para mostrar.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardCard09;
