import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../../utils/Transition';
import User03 from '../../images/user-36-03.jpg';
import { jwtDecode } from 'jwt-decode';

function UserMenu() {
  const userToken = localStorage.getItem('token');
  const decoded = userToken ? jwtDecode(userToken) : {};
  const { name: userName, lastName: userLastname, id: userId } = decoded;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen || !dropdown.current || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  function logOut() {
    localStorage.removeItem('token');
  }

  if (!userToken) {
    return null;
  }

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex items-center justify-center group p-2 hover:bg-gray-50 rounded-lg transition-colors duration-150"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="flex items-center">
          <div className="relative">
            <img 
              className="w-8 h-8 rounded-full border-2 border-gray-200" 
              src={User03} 
              width="32" 
              height="32" 
              alt="User" 
            />
            <div className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full"></div>
          </div>
          <span className="ml-3 text-sm font-medium text-gray-700 group-hover:text-gray-800">
            {userName}
          </span>
          <svg 
            className="w-4 h-4 ml-2 text-gray-400 group-hover:text-gray-500 transition-colors duration-150" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 w-64 bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="p-4 border-b border-gray-200">
            <div className="flex items-center mb-2">
              <div className="flex-shrink-0">
                <img 
                  className="w-10 h-10 rounded-full border-2 border-gray-200" 
                  src={User03} 
                  alt="User" 
                />
              </div>
              <div className="ml-3">
                <div className="text-sm font-semibold text-gray-900">{userName}</div>
                <div className="text-xs text-gray-500">{userLastname}</div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <Link
              to={`/profile/${userId}`}
              className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-lg hover:bg-gray-50 transition-colors duration-150"
              onClick={() => setDropdownOpen(false)}
            >
              <svg className="w-4 h-4 mr-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              Mi Perfil
            </Link>
            <a
              href="/login"
              className="flex items-center px-3 py-2 text-sm font-medium text-red-600 rounded-lg hover:bg-red-50 transition-colors duration-150"
              onClick={logOut}
            >
              <svg className="w-4 h-4 mr-3 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              Cerrar Sesión
            </a>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default UserMenu;