/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";

const Documents = (props) => (
  <li className="flex items-center px-4 py-3 hover:bg-gray-50 transition-colors duration-150">
    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mr-4">
      <svg className="w-6 h-6 text-blue-600" viewBox="0 0 36 36">
        <path d="M25 24H11a1 1 0 01-1-1v-5h2v4h12v-4h2v5a1 1 0 01-1 1zM14 13h8v2h-8z" />
      </svg>
    </div>
    <div className="flex-grow flex items-center border-b border-gray-100">
      <div className="flex-grow flex justify-between items-center">
        <div className="flex-grow min-w-0">
          <a 
            className="text-sm font-medium text-gray-800 hover:text-blue-600 truncate block" 
            href="#0"
            onClick={(e) => {
              e.preventDefault();
              props.viewDocument(props.document.url);
            }}
          >
            {props.document.nombredocumento}
          </a>
        </div>
        <div className="flex-shrink-0 ml-4">
          <button
            className="text-gray-400 hover:text-blue-600 rounded-full p-1 hover:bg-blue-50 transition-colors duration-150"
            onClick={() => props.viewDocument(props.document.url)}
            title="Descargar documento"
          >
            <span className="sr-only">Descargar documento</span>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="7 10 12 15 17 10" />
              <line x1="12" y1="15" x2="12" y2="3" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </li>
);

const FilesList = ({ idExpedient }) => {
  const [documentos, setDocumentos] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseAPI}/expedients/${idExpedient}`)
      .then((response) => {
        setDocumentos(response.data.documentacion);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [idExpedient]);

  const viewDocument = (fileUrl) => {
    const fileName = fileUrl.split("/").pop();
  
    axios
      .get(`${baseAPI}/expedients/get-new-url`, { params: { fileName } })
      .then((response) => {
        const signedUrl = response.data.url;
        window.open(signedUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error al obtener la URL prefirmada:", error);
        alert("No se pudo descargar el documento. Intenta nuevamente.");
      });
  };

  const documentsList = () => {
    return documentos.map((currentdocuments) => {
      return (
        <Documents
          document={currentdocuments}
          viewDocument={viewDocument}
          key={currentdocuments._id}
        />
      );
    });
  };

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Documentación</h2>
      </header>
      <div className="p-3">
        <div className="overflow-x-auto">
          <ul className="my-1">
            {documentos.length === 0 ? (
              <li className="px-4 py-3 text-sm text-gray-500 text-center">
                No hay documentos disponibles
              </li>
            ) : (
              documentsList()
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilesList;
