import React, { useState } from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ExpedientsAdmin from "../partials/components/Expedients-admin";
import { 
  FolderIcon, 
  MagnifyingGlassIcon, 
  PlusCircleIcon
} from '@heroicons/react/24/outline';

function ExpedientList() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const userToken = localStorage.getItem("token");
  
  if (userToken == null) {
    window.location = "/login";
  }

  const handleNewExpedient = () => {
    window.location = "/create-expedient";
  };

  return (
    <div className="flex h-screen overflow-hidden bg-slate-50">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Main content */}
        <div className="flex flex-col flex-1">
          <main className="flex-1">
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              
              {/* Page header */}
              <div className="sm:flex sm:items-center sm:justify-between mb-8">
                <div className="mb-4 sm:mb-0">
                  <div className="flex items-center">
                    <div className="mr-4">
                      <FolderIcon className="w-12 h-12 text-blue-600" />
                    </div>
                    <div>
                      <h1 className="text-2xl md:text-3xl font-bold text-slate-800">
                        Expedientes
                      </h1>
                      <p className="mt-1.5 text-sm text-slate-600">
                        Gestiona todas las reclamaciones y expedientes
                      </p>
                    </div>
                  </div>
                </div>

              </div>

              {/* Search and Actions */}
              <div className="sm:flex sm:items-center sm:justify-between mb-8">
                {/* Search Bar */}
                <div className="flex-1 max-w-2xl">
                  <form className="relative">
                    <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                      <MagnifyingGlassIcon className="h-5 w-5 text-slate-400" />
                    </div>
                    <input
                      id="expedient-search"
                      className="block w-full pl-11 pr-4 py-3 text-sm bg-white border border-slate-200 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150"
                      type="search"
                      placeholder="Buscar por deudor, cliente o importe..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </form>
                </div>

                {/* New Expedient Button */}
                <div className="mt-4 sm:mt-0 sm:ml-4">
                  <button
                    onClick={handleNewExpedient}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-150"
                  >
                    <PlusCircleIcon className="h-5 w-5 mr-2" />
                    Nueva reclamación
                  </button>
                </div>
              </div>

              {/* Expedients Grid */}
              <div className="bg-white rounded-xl border border-slate-200 overflow-hidden">
                <div className="grid grid-cols-12 gap-6">
                  <ExpedientsAdmin searchText={searchText} />
                </div>
              </div>
            </div>
          </main>

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ExpedientList;
