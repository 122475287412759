import React from "react";
import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import LogoImage from '../images/claim-up-19.png';

export default function SuccesPaymentDeptor() {
  return (
    <div className="min-h-screen bg-white">
      {/* Header Banner */}
      <div className="bg-gradient-to-b from-blue-50 to-white pt-12 pb-6">
        <div className="max-w-lg mx-auto px-4">
          <div className="text-center">
            <img
              src={LogoImage}
              width="180"
              height="180"
              alt="ClaimUp"
              className="mx-auto mb-6"
            />
            <h1 className="text-3xl font-bold text-slate-900 mb-3">
              ¡Pago Confirmado!
            </h1>
            <p className="text-lg text-slate-600">
              Tu transacción se ha completado con éxito
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-8">
        {/* Success Message Card */}
        <div className="bg-white rounded-2xl border border-slate-200 shadow-sm overflow-hidden">
          <div className="p-6">
            <div className="text-center">
              <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-blue-50 mb-4">
                <CheckCircleIcon className="h-8 w-8 text-blue-600" aria-hidden="true" />
              </div>
              
              <p className="mt-4 text-lg text-slate-600">
                Te confirmamos que tu pago ha sido realizado con éxito. En breve recibirás un correo electrónico con la confirmación y el detalle del mismo. 🚀
              </p>

              <div className="mt-8">
                <a
                  href="/"
                  className="w-full inline-flex justify-center items-center px-6 py-3 text-base font-medium rounded-xl text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150"
                >
                  Volver al inicio
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Security Badge */}
        <div className="flex justify-center space-x-8 mt-12">
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-3">
              <ShieldCheckIcon className="w-6 h-6 text-blue-600" />
            </div>
            <p className="text-sm font-medium text-slate-600">Pago Seguro</p>
          </div>
        </div>

        {/* Footer */}
        <footer className="mt-12 text-center border-t border-slate-200 pt-6">
          <a 
            href="https://www.claimup.io" 
            className="text-sm text-slate-500 hover:text-slate-700 transition-colors duration-150"
          >
            © {new Date().getFullYear()} ClaimUp · Todos los derechos reservados
          </a>
        </footer>
      </div>
    </div>
  );
}
