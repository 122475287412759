// InteractionsExpedient.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { baseAPI } from "../../config";

// Componente Funcional InteractionRow
const InteractionRow = ({ interaction }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Si el tipo de interacción es "email_opened", limpiamos los detalles
  if (interaction.type === "email_opened") {
    interaction.details = {};
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handlePlayRecording = async () => {
    
    if (!interaction.details?.call_id) {
      console.error('No hay ID de llamada disponible');
      return;
    }

    try {
      setIsLoading(true);
      
      const response = await axios.post(`${baseAPI}/actions/get-aircall-recording`, {
        callId: interaction.details.call_id
      });
      
      
      if (response.data.recordingUrl) {
        window.open(response.data.recordingUrl, '_blank');
      } else {
        console.error('No se recibió URL de grabación');
      }
    } catch (error) {
      console.error("Error al obtener la grabación:", error);
      alert("Error al obtener la grabación. Por favor, inténtalo de nuevo.");
    } finally {
      setIsLoading(false);
    }
  };

  const formattedDate = new Date(interaction.date).toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const emoJiDirection = interaction.direction === "outgoing" ? "📤" : "📥";

  // Verificar si hay detalles que mostrar
  const hasExpandableContent = 
    interaction.details?.message || 
    (interaction.type === "call_answered" && interaction.details?.call_id);

  // Verificar si es una llamada contestada
  const isCallAnswered = interaction.type === "call_answered";

  return (
    <>
      {/* Fila Principal */}
      <tr
        className={`${
          interaction.direction === "outgoing" 
            ? "bg-purple-50 hover:bg-purple-100" 
            : "bg-emerald-50 hover:bg-emerald-100"
        } transition-colors duration-150`}
      >
        <td className="px-2 first:pl-5 last:pr-5 py-3">
          <div className="flex items-center">
            <span className={`text-sm font-medium ${
              interaction.direction === "outgoing" 
                ? "text-purple-800"
                : "text-emerald-800"
            }`}>
              {interaction.type}
            </span>
            {(hasExpandableContent || isCallAnswered) && (
              <button
                onClick={toggleExpand}
                className={`ml-2 ${
                  interaction.direction === "outgoing"
                    ? "text-purple-400 hover:text-purple-600"
                    : "text-emerald-400 hover:text-emerald-600"
                } transition-colors duration-150`}
                aria-label={
                  isExpanded ? "Contraer detalles" : "Expandir detalles"
                }
              >
                <svg 
                  className={`w-4 h-4 transform transition-transform duration-150 ${
                    isExpanded ? 'rotate-180' : ''
                  }`}
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path d="M8 11.5l-5-5h10l-5 5z" />
                </svg>
              </button>
            )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3">
          <div className={`text-xs font-medium ${
            interaction.direction === "outgoing"
              ? "text-purple-600"
              : "text-emerald-600"
          }`}>{formattedDate}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3">
          <div className="flex items-center">
            <span className={`text-sm font-medium ${
              interaction.direction === "outgoing" 
                ? "text-purple-800"
                : "text-emerald-800"
            }`}>
              {interaction.direction === "outgoing" ? "Outgoing" : "Incoming"}
            </span>
            <span className="ml-2">{emoJiDirection}</span>
          </div>
        </td>
      </tr>

      {/* Fila Expandida */}
      {isExpanded && (
        <tr className={`${
          interaction.direction === "outgoing" 
            ? "bg-purple-50/50 border-y border-purple-100"
            : "bg-emerald-50/50 border-y border-emerald-100"
        }`}>
          <td colSpan="3" className="px-2 first:pl-5 last:pr-5 py-3">
            <div className="space-y-3">
              {/* Mostrar el mensaje si existe */}
              {interaction.details?.message && (
                <div className={`bg-white rounded-sm p-3 ${
                  interaction.direction === "outgoing"
                    ? "border border-purple-200"
                    : "border border-emerald-200"
                }`}>
                  <p className="text-sm text-gray-700">{interaction.details.message}</p>
                </div>
              )}

              {/* Mostrar el botón para escuchar la grabación */}
              {isCallAnswered && (
                <div className="flex items-center">
                  <button
                    onClick={handlePlayRecording}
                    className={`inline-flex items-center px-3 py-2 text-sm font-medium rounded-md text-white ${
                      interaction.direction === "outgoing"
                        ? "bg-blue-600 hover:bg-blue-700"
                        : "bg-blue-600 hover:bg-blue-700"
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors duration-150`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Cargando...
                      </>
                    ) : (
                      <>
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Escuchar Grabación
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const InteractionsExpedient = ({ idExpedient }) => {
  const [expedientid] = useState(idExpedient);
  const [interactions, setInteractions] = useState([]);
  const [anotacion, setAnotacion] = useState("");
  const [fecha] = useState(new Date());
  const [user_id, setUser_id] = useState("");
  const [usuario, setUsuario] = useState({});

  const loadInteractions = async () => {
    try {
      const response = await axios.get(`${baseAPI}/interactions/${expedientid}`);
      // Ordenar las interacciones por fecha descendente
      response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setInteractions(response.data);
      console.log("Interactions:", response.data);
    } catch (error) {
      console.error("Error fetching interactions:", error);
    }
  };

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      try {
        const decoded = jwtDecode(userToken);
        setUsuario(decoded);
        setUser_id(decoded.id);
        loadInteractions();
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [expedientid]); // Dependency array includes expedientid

  return (
    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">
          Interacciones{" "}
          <span className="text-gray-400 font-medium">
            {interactions.length}
          </span>
        </h2>
      </header>
      <div className="overflow-x-auto">
        <table className="table-auto w-full divide-y divide-gray-200">
          <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-gray-200">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-3 text-left">
                Tipo
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 text-left">
                Fecha
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 text-left">
                Dirección
              </th>
            </tr>
          </thead>

          <tbody className="text-sm divide-y divide-gray-200">
            {interactions.map((interaction) => (
              <InteractionRow
                key={interaction._id}
                interaction={interaction}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InteractionsExpedient;
