import React, { useState, useEffect } from "react";
import { baseAPI } from "../config";
import { useParams, Link } from "react-router-dom";
import { 
  ShieldCheckIcon, 
  CreditCardIcon, 
  CheckCircleIcon,
  EnvelopeIcon,
  ChatBubbleLeftRightIcon,
  LockClosedIcon,
  BanknotesIcon
} from '@heroicons/react/24/outline';

const GenerateLinkStripeToDebtor = () => {
  const { id } = useParams();
  const [urlStripe, setUrlStripe] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      setError("No se ha podido identificar el expediente");
      setLoading(false);
      return;
    }

    let source = "not defined";
    const currentUrl = window.location.href;
    if (currentUrl.includes("sms")) source = "sms";
    else if (currentUrl.includes("email")) source = "email";
    else if (currentUrl.includes("chat")) source = "chat";

    const fetchExpedient = async () => {
      try {
        const response = await fetch(`${baseAPI}/actions/create-url-stripe`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ expedientId: id, source }),
        });
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "No se ha podido generar el enlace de pago");
        }
        setUrlStripe(data.stripeUrl);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchExpedient();
  }, [id]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white px-4">
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-50 mb-4">
            <ShieldCheckIcon className="w-8 h-8 text-red-500" />
          </div>
          <h2 className="text-2xl font-bold text-slate-800 mb-2">No se ha podido generar el enlace</h2>
          <p className="text-slate-600 mb-6">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="inline-flex items-center px-6 py-3 text-sm font-semibold rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Header Banner */}
      <div className="bg-gradient-to-b from-blue-50 to-white pt-12 pb-6">
        <div className="max-w-3xl mx-auto px-4">
          <div className="text-center">
            <div className="inline-flex items-center justify-center p-3 bg-blue-600 rounded-xl mb-6">
              <LockClosedIcon className="w-8 h-8 text-white" />
            </div>
            <h1 className="text-3xl font-bold text-slate-900 mb-3">
              Plataforma de Pago Seguro
            </h1>
            <p className="text-lg text-slate-600">
              Tu seguridad es nuestra prioridad
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-3xl mx-auto px-4 py-8">
        {/* Security Badges */}
        <div className="flex justify-center space-x-8 mb-12">
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-slate-100 rounded-lg mb-3">
              <ShieldCheckIcon className="w-6 h-6 text-blue-600" />
            </div>
            <p className="text-sm font-medium text-slate-600">Pago Seguro</p>
          </div>
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-slate-100 rounded-lg mb-3">
              <BanknotesIcon className="w-6 h-6 text-blue-600" />
            </div>
            <p className="text-sm font-medium text-slate-600">Transacción Verificada</p>
          </div>
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-slate-100 rounded-lg mb-3">
              <CreditCardIcon className="w-6 h-6 text-blue-600" />
            </div>
            <p className="text-sm font-medium text-slate-600">Datos Protegidos</p>
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-white border border-slate-200 rounded-2xl shadow-sm overflow-hidden">
          {/* Payment Methods Section */}
          <div className="p-6 border-b border-slate-200">
            <h2 className="text-lg font-semibold text-slate-900 mb-4">
              Métodos de pago disponibles
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center p-4 bg-slate-50 rounded-lg">
                <CheckCircleIcon className="w-5 h-5 text-green-500 mr-3" />
                <span className="text-slate-700">Tarjeta de crédito/débito</span>
              </div>
              <div className="flex items-center p-4 bg-slate-50 rounded-lg">
                <CheckCircleIcon className="w-5 h-5 text-green-500 mr-3" />
                <span className="text-slate-700">Apple Pay</span>
              </div>
              <div className="flex items-center p-4 bg-slate-50 rounded-lg">
                <CheckCircleIcon className="w-5 h-5 text-green-500 mr-3" />
                <span className="text-slate-700">Google Pay</span>
              </div>
              <div className="flex items-center p-4 bg-slate-50 rounded-lg">
                <CheckCircleIcon className="w-5 h-5 text-green-500 mr-3" />
                <span className="text-slate-700">Transferencia bancaria</span>
              </div>
            </div>
          </div>

          {/* Process Steps */}
          <div className="p-6 border-b border-slate-200 bg-gradient-to-b from-slate-50 to-white">
            <h2 className="text-lg font-semibold text-slate-900 mb-4">
              Proceso simplificado y seguro
            </h2>
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                  <span className="text-blue-600 font-semibold">1</span>
                </div>
                <div>
                  <h3 className="text-slate-800 font-medium mb-1">Pago Seguro</h3>
                  <p className="text-slate-600 text-sm">Procesado por Stripe, líder mundial en seguridad de pagos online</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                  <span className="text-blue-600 font-semibold">2</span>
                </div>
                <div>
                  <h3 className="text-slate-800 font-medium mb-1">Confirmación Inmediata</h3>
                  <p className="text-slate-600 text-sm">Recibirás un email con el comprobante de pago al instante</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                  <span className="text-blue-600 font-semibold">3</span>
                </div>
                <div>
                  <h3 className="text-slate-800 font-medium mb-1">Expediente Resuelto</h3>
                  <p className="text-slate-600 text-sm">Tu deuda quedará completamente saldada sin reclamaciones futuras</p>
                </div>
              </div>
            </div>
          </div>

          {/* Payment Button Section */}
          <div className="p-6 bg-white">
            {loading ? (
              <div className="flex justify-center py-6">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-600"></div>
              </div>
            ) : urlStripe && (
              <div className="text-center">
                <a
                  href={urlStripe}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-8 py-4 text-base font-semibold rounded-xl text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-150 shadow-sm"
                >
                  <LockClosedIcon className="w-5 h-5 mr-2" />
                  Realizar pago seguro
                </a>
                <p className="mt-4 text-sm text-slate-500">
                  Serás redirigido a nuestra plataforma segura de pago
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Contact Options */}
        <div className="mt-8 text-center">
          <p className="text-slate-600 mb-4">¿Necesitas ayuda?</p>
          <div className="flex justify-center space-x-4">
            <a
              href="mailto:legal@claimup.io"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-slate-700 hover:text-slate-900 transition-colors duration-150"
            >
              <EnvelopeIcon className="w-5 h-5 mr-2 text-slate-400" />
              legal@claimup.io
            </a>
            <Link
              to={`/chat-debtor/${id}`}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-slate-700 hover:text-slate-900 transition-colors duration-150"
            >
              <ChatBubbleLeftRightIcon className="w-5 h-5 mr-2 text-slate-400" />
              Chat de soporte
            </Link>
          </div>
        </div>

        {/* Footer */}
        <footer className="mt-12 text-center border-t border-slate-200 pt-6">
          <a 
            href="https://www.claimup.io" 
            className="text-sm text-slate-500 hover:text-slate-700 transition-colors duration-150"
          >
            © {new Date().getFullYear()} ClaimUp · Todos los derechos reservados
          </a>
        </footer>
      </div>
    </div>
  );
};

export default GenerateLinkStripeToDebtor;
