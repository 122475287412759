import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { baseAPI } from "../config";
import { toast } from "react-toastify";
import LogoImage from "../images/claim-up-19.png";
import { 
  LockClosedIcon,
  ShieldCheckIcon,
  EnvelopeIcon,
  KeyIcon,
  ArrowRightOnRectangleIcon,
  UserPlusIcon
} from '@heroicons/react/24/outline';

const Login = () => {
  const [usermail, setUsermail] = useState("");
  const [userpassword, setUserpassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/expedients");
    }
  }, [navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${baseAPI}/users/login`, {
        usermail,
        userpassword,
      });

      const token = response.data.token;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = token;
      
      toast.success("¡Bienvenido a ClaimUp! 👋", {
        position: "top-right",
        autoClose: 2000,
      });

      setTimeout(() => {
        navigate("/expedients");
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error("Credenciales incorrectas", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header Banner */}
      <div className="bg-gradient-to-b from-blue-50 to-white pt-12 pb-6">
        <div className="max-w-lg mx-auto px-4">
          <div className="text-center">
            <img
              src={LogoImage}
              width="180"
              height="180"
              alt="ClaimUp"
              className="mx-auto mb-6"
            />
            <h1 className="text-3xl font-bold text-slate-900 mb-3">
              Bienvenido a ClaimUp
            </h1>
            <p className="text-lg text-slate-600">
              Gestiona tus reclamaciones de forma profesional
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-8">
        {/* Security Badges */}
        <div className="flex justify-center space-x-8 mb-12">
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-3">
              <ShieldCheckIcon className="w-6 h-6 text-blue-600" />
            </div>
            <p className="text-sm font-medium text-slate-600">Acceso Seguro</p>
          </div>
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-3">
              <LockClosedIcon className="w-6 h-6 text-blue-600" />
            </div>
            <p className="text-sm font-medium text-slate-600">Datos Protegidos</p>
          </div>
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-3">
              <ArrowRightOnRectangleIcon className="w-6 h-6 text-blue-600" />
            </div>
            <p className="text-sm font-medium text-slate-600">Acceso Rápido</p>
          </div>
        </div>

        {/* Login Form */}
        <div className="bg-white rounded-2xl border border-slate-200 shadow-sm overflow-hidden">
          <div className="p-6">
            <form onSubmit={onSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2" htmlFor="email">
                  Correo electrónico
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <EnvelopeIcon className="h-5 w-5 text-slate-400" />
                  </div>
                  <input
                    id="email"
                    type="email"
                    required
                    value={usermail}
                    onChange={(e) => setUsermail(e.target.value)}
                    className="block w-full pl-10 pr-3 py-2.5 text-slate-900 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors duration-150"
                    placeholder="tu@email.com"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2" htmlFor="password">
                  Contraseña
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <KeyIcon className="h-5 w-5 text-slate-400" />
                  </div>
                  <input
                    id="password"
                    type="password"
                    required
                    value={userpassword}
                    onChange={(e) => setUserpassword(e.target.value)}
                    className="block w-full pl-10 pr-3 py-2.5 text-slate-900 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors duration-150"
                    placeholder="••••••••"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-slate-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-slate-600">
                    Recordarme
                  </label>
                </div>

                <Link
                  to="/forgot-password"
                  className="text-sm font-medium text-blue-600 hover:text-blue-700 transition-colors duration-150"
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center items-center px-6 py-3 text-base font-medium rounded-xl text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150 ${
                  isLoading ? "opacity-75 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Iniciando sesión...
                  </>
                ) : (
                  <>
                    <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2" />
                    Iniciar sesión
                  </>
                )}
              </button>
            </form>
          </div>
        </div>

        {/* Register CTA */}
        <div className="mt-8 text-center">
          <p className="text-slate-600 mb-4">¿Aún no tienes cuenta?</p>
          <Link
            to="/signup"
            className="inline-flex items-center px-6 py-3 border border-slate-200 rounded-xl text-base font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150"
          >
            <UserPlusIcon className="w-5 h-5 mr-2" />
            Crear cuenta gratuita
          </Link>
        </div>

        {/* Footer */}
        <footer className="mt-12 text-center border-t border-slate-200 pt-6">
          <a 
            href="https://www.claimup.io" 
            className="text-sm text-slate-500 hover:text-slate-700 transition-colors duration-150"
          >
            © {new Date().getFullYear()} ClaimUp · Todos los derechos reservados
          </a>
        </footer>
      </div>
    </div>
  );
};

export default Login;
