import React from 'react';
import UserSelect from "./userSelectList";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

const ExpedientsFilters = ({ 
  showFilter, 
  selectedEstado, 
  setSelectedEstado, 
  selectedUser, 
  setSelectedUser, 
  role,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) => {
  if (!showFilter) return null;

  return (
    <div className="bg-white shadow-sm rounded-lg border border-gray-200 p-6 mb-6">
      <div className="space-y-6">
        <div>
          <label
            htmlFor="estadoFilter"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Estado
          </label>
          <select
            id="estadoFilter"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={selectedEstado}
            onChange={(e) => setSelectedEstado(e.target.value)}
          >
            <option value="">Todos los estados</option>
            <option value="On going">On going</option>
            <option value="Email opened">Email opened</option>
            <option value="Bounced">Bounced</option>
            <option value="Hot">Hot</option>
            <option value="Cold">Cold</option>
            <option value="Fallido">Fallido</option>
            <option value="Ilocalizable">Ilocalizable</option>
            <option value="Sin datos de contacto">Sin datos de contacto</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Dispute">Dispute</option>
            <option value="Burofax Enviado">Burofax Enviado</option>
            <option value="Burofax con resultado negativo">Burofax con resultado negativo</option>
            <option value="Burofax con resultado positivo">Burofax con resultado positivo</option>
            <option value="Demanda pendiente">Demanda pendiente</option>
            <option value="Demanda preparada">Demanda preparada</option>
            <option value="Demanda presentada">Demanda presentada</option>
            <option value="Demanda Admitida">Demanda Admitida</option>
            <option value="Demanda No Admitida">Demanda No Admitida</option>
            <option value="Oposición del Deudor">Oposición del Deudor</option>
            <option value="Pendiente Requerimiento">Pendiente Requerimiento</option>
            <option value="Requerimiento Judicial Positivo">Requerimiento Judicial Positivo</option>
            <option value="Pendiente Ejecucion">Pendiente Ejecucion</option>
            <option value="Sentencia Estimatoria">Sentencia Estimatoria</option>
            <option value="Sentencia Desestimatoria">Sentencia Desestimatoria</option>
            <option value="Sentencia Parcial">Sentencia Parcial</option>
            <option value="Cobrado">Cobrado</option>
            <option value="Cobrado Judicialmente">Cobrado Judicialmente</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Rango de fechas
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="startDate" className="sr-only">
                Fecha inicio
              </label>
              <Flatpickr
                id="startDate"
                value={startDate}
                onChange={([date]) => setStartDate(date)}
                placeholder="Fecha inicio"
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                dateFormat="d/m/Y"
                maxDate={endDate || undefined}
              />
            </div>
            <div>
              <label htmlFor="endDate" className="sr-only">
                Fecha fin
              </label>
              <Flatpickr
                id="endDate"
                value={endDate}
                onChange={([date]) => setEndDate(date)}
                placeholder="Fecha fin"
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                dateFormat="d/m/Y"
                minDate={startDate || undefined}
              />
            </div>
          </div>
        </div>

        {role === "admin" && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Cliente
            </label>
            <UserSelect
              selectedUser={selectedUser}
              onUserChange={(user) => setSelectedUser(user)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpedientsFilters; 