import React, { useRef, useEffect } from "react";
import User01 from "../../images/claimup-chat.png";
import { UserCircleIcon } from '@heroicons/react/24/solid';

function MessagesBody({ historyChat, assistantTyping }) {
  const messagesEndRef = useRef(null);

  // Helper function to format dates for messages
  const formatMessageDate = (dateString) => {
    const options = { hour: "2-digit", minute: "2-digit" };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  // Function to format date for displaying as separator
  const formatDateSeparator = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  // Group messages by date
  const messagesByDate = historyChat.reduce((acc, message) => {
    if (message.role === "system") {
      return acc;
    }

    // Extrae solo la fecha sin la hora
    const date = message.createdAt
      ? new Date(message.createdAt).toISOString().split("T")[0]
      : "Fecha desconocida";

    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});

  const maskURLs = (text) => {
    // Eliminamos cualquier variante del formato markdown de enlaces de pago
    let cleanText = text
      .replace(/\[(Enlace de Pago|Generar Enlace de Pago)\]\((https?:\/\/[^\s)]+)\)/g, '$2')
      .replace(/este enlace seguro de Stripe:\s*/g, '')
      .replace(/https\.\s*/, ''); // Elimina "https." suelto
    
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    
    // Si no hay URLs, devolvemos el texto limpio
    if (!urlRegex.test(cleanText)) return cleanText;

    // Dividimos el texto en partes: antes de la URL y después
    const parts = cleanText.split(urlRegex);
    
    // Construimos el resultado final
    let result = parts[0].trim(); // Texto antes de la URL
    
    // Encontramos todas las URLs en el texto
    const urls = cleanText.match(urlRegex);
    if (urls) {
      urls.forEach(url => {
        if (result && !result.endsWith('.') && !result.endsWith(':') && !result.endsWith(' ')) {
          result += ' ';
        }
        result += `
          <div class="mt-4 mb-2">
            <a href="${url}" 
               target="_blank" 
               rel="noopener noreferrer" 
               class="inline-flex items-center px-5 py-2.5 bg-green-600 text-white text-sm font-medium rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-150 shadow-sm">
              <span>Realizar pago</span>
              <svg class="ml-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6"/>
              </svg>
            </a>
          </div>`;
      });
    }

    // Añadimos el texto después de la última URL, si existe y no es la URL en sí
    const lastPart = parts[parts.length - 1];
    if (lastPart && !urlRegex.test(lastPart)) {
      result += lastPart.trim();
    }

    return result;
  };
  
  // Scroll to the bottom whenever historyChat changes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [historyChat]);

  return (
    <div className="h-full">
      <div className="max-w-4xl mx-auto p-6">
        {Object.entries(messagesByDate).map(([date, messages], index) => (
          <React.Fragment key={index}>
            <div className="flex justify-center">
              <div className="inline-flex items-center justify-center text-xs font-medium px-3 py-1.5 bg-slate-100 text-slate-600 rounded-full my-6">
                {formatDateSeparator(date)}
              </div>
            </div>
            {messages.map((chat, msgIndex) => {
              const isAssistant = chat.role === "assistant";
              return (
                <div key={msgIndex} className={`flex items-start mb-6 last:mb-0 ${isAssistant ? '' : 'flex-row-reverse'}`}>
                  {isAssistant ? (
                    <img
                      className="rounded-full mr-4"
                      src={User01}
                      width="40"
                      height="40"
                      alt="Asistente"
                    />
                  ) : (
                    <UserCircleIcon className="w-10 h-10 ml-4 text-slate-400" />
                  )}
                  <div className={`flex flex-col ${isAssistant ? '' : 'items-end'}`}>
                    <div
                      className={`text-sm p-4 rounded-2xl shadow-sm max-w-xl break-words ${
                        isAssistant
                          ? "bg-white text-slate-800 border border-slate-200"
                          : "bg-blue-600 text-white"
                      }`}
                      dangerouslySetInnerHTML={{ __html: maskURLs(chat.content) }}
                    />
                    <div className={`text-xs text-slate-400 font-medium mt-2 ${isAssistant ? 'ml-2' : 'mr-2'}`}>
                      {formatMessageDate(chat.createdAt)}
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        ))}
        {assistantTyping && (
          <div className="flex items-start mt-6">
            <img
              className="rounded-full mr-4"
              src={User01}
              width="40"
              height="40"
              alt="Asistente"
            />
            <div className="bg-white text-slate-800 p-4 rounded-2xl border border-slate-200 shadow-sm">
              <div className="flex items-center space-x-1.5">
                <div className="w-1.5 h-1.5 bg-blue-500/40 rounded-full animate-typing-1"></div>
                <div className="w-1.5 h-1.5 bg-blue-500/40 rounded-full animate-typing-2"></div>
                <div className="w-1.5 h-1.5 bg-blue-500/40 rounded-full animate-typing-3"></div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
}

export default MessagesBody;
