import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseAPI } from "../config";

const CompleteUserProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [formData, setFormData] = useState({
    username: "",
    userlastname: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = {
        username: formData.username,
        userlastname: formData.userlastname,
        userid: id
      };

      const response = await axios.post(`${baseAPI}/users/updateUserProfile`, user);
      const { token } = response.data;
      
      if (token) {
        localStorage.setItem("token", token);
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Aquí podrías añadir un toast o alguna notificación de error
    }
  };

  return (
    <div className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0">
      <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
        <section>
          <h3 className="font-bold text-2xl text-center">
            Completa tu perfil
          </h3>
        </section>

        <section className="mt-10">
          <form
            className="flex flex-col"
            onSubmit={handleSubmit}
          >
            <div className="mb-6 pt-3 rounded">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                htmlFor="username"
              >
                Nombre
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
              />
            </div>
            <div className="mb-6 pt-3 rounded">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                htmlFor="userlastname"
              >
                Apellidos
              </label>
              <input
                type="text"
                id="userlastname"
                name="userlastname"
                value={formData.userlastname}
                onChange={handleChange}
                className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
              />
            </div>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
              type="submit"
            >
              Confirmar
            </button>
          </form>
        </section>
      </main>

      <footer className="max-w-lg mx-auto flex justify-center text-white">
        <a href="https://www.claimup.io" className="hover:underline">
          www.claimup.io
        </a>
      </footer>
    </div>
  );
};

export default CompleteUserProfile;
