import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import Tooltip from "../components/Tooltip";

const NewDebtChart = ({ expedientes }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: "Nueva Deuda",
      data: [],
      backgroundColor: "#3b82f6", // Azul principal
      hoverBackgroundColor: "#2563eb", // Azul hover
      borderRadius: 8,
      borderWidth: 0,
      barPercentage: 0.6,
      categoryPercentage: 0.7,
    }]
  });

  useEffect(() => {
    // Agrupar deuda por mes
    const groupedData = expedientes.reduce((acc, expediente) => {
      const date = new Date(expediente.createdAt);
      const monthYear = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      
      if (!acc[monthYear]) {
        acc[monthYear] = 0;
      }
      acc[monthYear] += expediente.importe_deuda || 0;
      return acc;
    }, {});

    // Ordenar por fecha
    const sortedMonths = Object.keys(groupedData).sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA - dateB;
    });

    setChartData({
      labels: sortedMonths,
      datasets: [{
        ...chartData.datasets[0],
        data: sortedMonths.map(month => groupedData[month])
      }]
    });
  }, [expedientes]);

  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "#1e40af",
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        borderColor: "#3b82f6",
        borderWidth: 1,
        padding: 12,
        cornerRadius: 8,
        displayColors: false,
        titleFont: {
          size: 14,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `${Number(value).toLocaleString('es-ES')} €`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#64748b",
          font: {
            size: 12,
          },
          maxRotation: 45,
          minRotation: 45,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#e2e8f0",
          drawBorder: false,
          borderDash: [5, 5],
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => `${Number(value).toLocaleString('es-ES')} €`,
          color: "#64748b",
          font: {
            size: 12,
          },
          padding: 10,
        },
        border: {
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    animation: {
      duration: 500,
    },
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-sm rounded-xl border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
          <div className="flex items-center space-x-2">
            <h2 className="font-semibold text-slate-800">Nueva Deuda por Mes</h2>
            <Tooltip className="ml-2">
              <div className="text-sm">
                Muestra el total de nueva deuda asignada cada mes.
              </div>
            </Tooltip>
          </div>
        </div>
      </header>

      <div className="flex-grow px-5 pt-3 pb-5">
        <div style={{ height: "300px" }}>
          {chartData.labels.length > 0 ? (
            <Bar data={chartData} options={barChartOptions} />
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-slate-500 text-sm">No hay datos disponibles para mostrar.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewDebtChart; 