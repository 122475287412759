import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseAPI } from "../config";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import FilesList from "../partials/components/Files-list";
import Notes from "../partials/components/Notes";
import UploadFiles from "../services/uploadfiles";
import MoneyComeBack from "../partials/components/MoneyComeBack";
import Debtors from "../partials/components/Debtors";
import UpdateExpedient from "../partials/components/UpdateExpedient";
import InteractionsExpedient from "../partials/components/Interactions";
import CurrencyFormat from "react-currency-format";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

const getCurrencySymbol = (divisa) => {
  if (!divisa) return '€';
  
  const symbols = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
    'JPY': '¥',
    'CHF': 'CHF',
    'AUD': 'A$',
    'CAD': 'C$',
    'CNY': '¥'
  };
  return symbols[divisa.toUpperCase()] || '€';
};

const CurrencyFormatComponent = ({ value, divisa }) => {
  const actualDivisa = divisa || 'EUR';
  return (
    <CurrencyFormat
      value={value}
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
      suffix={` ${getCurrencySymbol(actualDivisa)}`}
      decimalScale={2}
      fixedDecimalScale={true}
      renderText={(value) => <>{value}</>}
    />
  );
};

function ExpedientView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [expedient, setExpedient] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [activeView, setActiveView] = useState("notes");
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      toast.error("ID de expediente no válido");
      navigate("/dashboard");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Sesión no válida");
      navigate("/login");
      return;
    }

    try {
      const decoded = jwtDecode(token);
      setUserRole(decoded.role);

      const headers = {
        Authorization: `Bearer ${token}`
      };

      axios
        .get(`${baseAPI}/expedients/${id}`, { headers })
        .then((response) => {
          setExpedient(response.data);
          const viewFromLocalStorage = localStorage.getItem("activeView") || "notes";
          setActiveView(viewFromLocalStorage);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching expedient:", error);
          toast.error("Error al cargar el expediente");
          setIsLoading(false);
          if (error.response?.status === 401) {
            navigate("/login");
          }
        });
    } catch (error) {
      console.error("Error decoding token:", error);
      toast.error("Error de autenticación");
      navigate("/login");
    }
  }, [id, navigate]);

  const changeView = (view) => {
    setActiveView(view);
    localStorage.setItem("activeView", view);
  };

  if (isLoading || !expedient) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="bg-white shadow-lg rounded-sm border border-gray-200">
              {/* Header Section */}
              <header className="px-5 py-4 border-b border-gray-100">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-4">
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">Deudor Principal</h2>
                      <div className="mt-1 flex items-center space-x-2">
                        <span className="text-lg font-semibold text-gray-800">
                          {expedient.deudores_id[0]?.debtorname || "Desconocido"}
                        </span>
                        <span className="text-sm text-gray-500">
                          {expedient.deudores_id[0]?.debtornif || "NIF no disponible"}
                        </span>
                      </div>
                    </div>

                    <div>
                      <h2 className="text-sm font-medium text-gray-500">Deuda Reclamada</h2>
                      <p className="mt-1 text-2xl font-bold text-gray-800">
                        <CurrencyFormatComponent
                          value={expedient.importe_deuda}
                          divisa={expedient.divisa}
                        />
                      </p>
                    </div>

                    <button
                      onClick={() => setExpanded(!expanded)}
                      className="inline-flex items-center text-sm text-blue-600 hover:text-blue-700 transition-colors duration-150"
                    >
                      <svg
                        className={`w-4 h-4 mr-1 transform transition-transform duration-200 ${
                          expanded ? "rotate-180" : ""
                        }`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                      {expanded ? "Ocultar detalles" : "Mostrar más detalles"}
                    </button>

                    {expanded && (
                      <div className="mt-4 space-y-4 pt-4 border-t border-gray-100">
                        <div>
                          <h2 className="text-sm font-medium text-gray-500">Cliente</h2>
                          <p className="mt-1 text-base text-gray-800">
                            {expedient.user_id?.usermail || "Sin asignar"}
                          </p>
                        </div>

                        <div>
                          <h2 className="text-sm font-medium text-gray-500">Expediente</h2>
                          <p className="mt-1 text-base text-gray-800">{id}</p>
                        </div>

                        <div>
                          <h2 className="text-sm font-medium text-gray-500">Detalle de la deuda</h2>
                          <div className="mt-2 space-y-3">
                            {expedient.detalle_deuda.map((detalle, index) => (
                              <div
                                key={index}
                                className="bg-gray-50 rounded-lg p-3 border border-gray-100"
                              >
                                {Object.entries(detalle).map(([key, value]) => (
                                  <div
                                    key={key}
                                    className="flex items-center justify-between text-sm"
                                  >
                                    <span className="text-gray-600 capitalize">
                                      {key.replace(/_/g, " ")}
                                    </span>
                                    <span className="text-gray-800 font-medium">
                                      {typeof value === "number" ? (
                                        <CurrencyFormatComponent
                                          value={value}
                                          divisa={expedient.divisa}
                                        />
                                      ) : (
                                        value
                                      )}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col items-end justify-center">
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                      {expedient.estado}
                    </span>
                  </div>
                </div>
              </header>

              {/* Tabs Section */}
              <section className="px-5 py-4">
                <div className="flex flex-wrap -m-1">
                  {[
                    { label: "Interacciones", value: "interactions", icon: "M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" },
                    { label: "Anotaciones", value: "notes", icon: "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" },
                    { label: "Documentos", value: "documents", icon: "M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" },
                    { label: "Deuda", value: "debt", icon: "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" },
                    { label: "Deudores", value: "debtors", icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" },
                    ...(userRole === "admin"
                      ? [
                          {
                            label: "Datos del expediente",
                            value: "expedientData",
                            icon: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                          },
                        ]
                      : []),
                  ].map((tab) => (
                    <button
                      key={tab.value}
                      className={`m-1 inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium transition-colors duration-150 ${
                        activeView === tab.value
                          ? "bg-blue-600 text-white"
                          : "bg-white text-gray-600 hover:bg-gray-50 border border-gray-200"
                      }`}
                      onClick={() => changeView(tab.value)}
                    >
                      <svg
                        className={`w-4 h-4 mr-1.5 ${
                          activeView === tab.value ? "text-white" : "text-gray-400"
                        }`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d={tab.icon}
                        />
                      </svg>
                      {tab.label}
                    </button>
                  ))}
                </div>
              </section>

              {/* Content Section */}
              <section className="px-5 py-4 border-t border-gray-100">
                {activeView === "notes" && <Notes idExpedient={id} />}
                {activeView === "debt" && <MoneyComeBack idExpedient={id} />}
                {activeView === "debtors" && <Debtors idExpedient={id} />}
                {activeView === "expedientData" && userRole === "admin" && (
                  <UpdateExpedient idExpedient={id} />
                )}
                {activeView === "documents" && (
                  <div className="space-y-6">
                    <FilesList idExpedient={id} />
                    <UploadFiles idExpedient={id} />
                  </div>
                )}
                {activeView === "interactions" && (
                  <InteractionsExpedient idExpedient={id} />
                )}
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ExpedientView;
