import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { baseAPI } from "../../config";

function TasksList() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const fetchTasks = async () => {
          try {
            const response = await axios.post(`${baseAPI}/annotations/alltasks`, {
              user_id: decoded.id,
              role: decoded.role,
            });

            if (decoded.role !== "admin") {
              const filteredTasks = response.data.filter(
                (task) => task.creator_role === "admin"
              );
              setTasks(filteredTasks);
              return;
            }
            setTasks(response.data);
          } catch (error) {
            console.error("Failed to fetch tasks:", error);
            toast.error("Error al cargar las tareas");
          } finally {
            setLoading(false);
          }
        };

        fetchTasks();
      } catch (error) {
        console.error("Error decoding token:", error);
        toast.error("Error con el token de autenticación");
      }
    } else {
      toast.error("No se encontró el token de autenticación");
    }
  }, []);

  const onComplete = async (taskId, expedientId) => {
    try {
      await axios.put(`${baseAPI}/annotations/completedtask/${taskId}`, {
        expedient_id: expedientId,
      });
      toast.success("Tarea completada!");
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId ? { ...task, tarea: "Completada" } : task
        )
      );
    } catch (error) {
      console.error("Failed to complete task:", error);
      toast.error("Error al completar la tarea");
    }
  };

  // Separar y ordenar tareas
  const pendingTasks = tasks
    .filter((task) => task.tarea === "Pendiente")
    .sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

  const completedTasks = tasks
    .filter((task) => task.tarea === "Completada")
    .sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

  return (
    <div className="col-span-full xl:col-span-12">
      {/* Panel */}
      <div className="bg-white shadow-lg rounded-sm border border-slate-200">
        {/* Panel header */}
        <header className="px-5 py-4 border-b border-slate-100">
          <h2 className="font-semibold text-slate-800">
            Tareas <span className="text-slate-400 font-medium">{tasks.length}</span>
          </h2>
        </header>

        <div className="p-3">
          {/* Loading state */}
          {loading ? (
            <div className="text-center py-10">
              <div className="inline-flex items-center justify-center">
                <svg className="w-6 h-6 animate-spin text-slate-400" viewBox="0 0 24 24" fill="none">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                <span className="ml-2 text-sm font-medium text-slate-500">Cargando...</span>
              </div>
            </div>
          ) : (
            <div className="space-y-2">
              {/* Pending Tasks Section */}
              {pendingTasks.length > 0 && (
                <div>
                  <h3 className="text-sm font-semibold text-slate-800 px-2 mb-2">
                    Pendientes ({pendingTasks.length})
                  </h3>
                  {pendingTasks.map((task) => (
                    <div key={task._id} className="px-2 py-3 rounded-sm border border-slate-200 hover:border-slate-300">
                      <div className="flex items-start justify-between">
                        <div className="grow flex items-start space-x-3">
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-slate-300 text-blue-600 focus:ring-blue-500 mt-1"
                            onChange={(e) => e.target.checked && onComplete(task._id, task.expedient_id)}
                          />
                          <div>
                            <div className="text-sm font-medium text-slate-800">{task.anotacion}</div>
                            {task.expedient_id?.deudores_id?.[0] && (
                              <div className="mt-0.5 text-xs text-slate-500">
                                {task.expedient_id.deudores_id[0].debtorname} · 
                                {task.expedient_id.deudores_id[0].debtornif}
                              </div>
                            )}
                            <div className="mt-0.5 text-xs text-slate-400">
                              {new Date(task.fecha).toLocaleDateString()} · {task.creator_name}
                            </div>
                          </div>
                        </div>
                        <a
                          href={`/view/${task.expedient_id?._id}`}
                          className="shrink-0 text-slate-400 hover:text-slate-500 ml-2"
                        >
                          <span className="sr-only">Ver expediente</span>
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* Completed Tasks Section */}
              {completedTasks.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-sm font-semibold text-slate-800 px-2 mb-2">
                    Completadas ({completedTasks.length})
                  </h3>
                  {completedTasks.map((task) => (
                    <div key={task._id} className="px-2 py-3 rounded-sm bg-slate-50">
                      <div className="flex items-start justify-between opacity-60">
                        <div className="grow flex items-start space-x-3">
                          <div className="h-4 w-4 mt-1">✓</div>
                          <div>
                            <div className="text-sm font-medium text-slate-800 line-through">
                              {task.anotacion}
                            </div>
                            {task.expedient_id?.deudores_id?.[0] && (
                              <div className="mt-0.5 text-xs text-slate-500">
                                {task.expedient_id.deudores_id[0].debtorname} · 
                                {task.expedient_id.deudores_id[0].debtornif}
                              </div>
                            )}
                            <div className="mt-0.5 text-xs text-slate-400">
                              {new Date(task.fecha).toLocaleDateString()} · {task.creator_name}
                            </div>
                          </div>
                        </div>
                        <a
                          href={`/view/${task.expedient_id?._id}`}
                          className="shrink-0 text-slate-400 hover:text-slate-500 ml-2"
                        >
                          <span className="sr-only">Ver expediente</span>
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* Empty State */}
              {tasks.length === 0 && (
                <div className="text-center py-10">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-slate-100 mb-4">
                    <svg className="w-8 h-8 text-slate-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                  </div>
                  <h3 className="text-base font-medium text-slate-800">No hay tareas</h3>
                  <p className="text-sm text-slate-500 mt-1">No hay tareas pendientes ni completadas.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TasksList;
