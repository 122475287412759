import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";

const Users = (props) => (
  <tr className="hover:bg-gray-50 transition-colors duration-150">
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="text-sm font-medium text-gray-900">{props.user.username}</div>
      </div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">{props.user.userlastname}</div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">{props.user.usermail}</div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">{props.user.userphone}</div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
      <div className="flex items-center justify-end">
        <button
          className="text-blue-600 hover:text-blue-900 transition-colors duration-150"
          onClick={() => props.viewUser(props.user._id)}
          title="Editar usuario"
        >
          <span className="sr-only">Editar</span>
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
          </svg>
        </button>
      </div>
    </td>
  </tr>
);

const UsersRegister = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseAPI}/users/`, {
        headers: { Authorization: localStorage.getItem("SavedToken") },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error al cargar los usuarios", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  const viewUser = (id) => {
    window.location = `/profile/${id}`;
  };

  const userList = () => {
    return users.map((currentuser) => {
      return (
        <Users
          user={currentuser}
          viewUser={viewUser}
          key={currentuser._id}
        />
      );
    });
  };

  return (
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-6 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800 text-lg">Usuarios registrados</h2>
      </header>
      <div className="p-6">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nombre
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Apellido
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Teléfono
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userList()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersRegister;
