import React, { useState, useEffect } from "react";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";

const getCurrencySymbol = (divisa) => {
  if (!divisa) return '€';
  
  const symbols = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
    'JPY': '¥',
    'CHF': 'CHF',
    'AUD': 'A$',
    'CAD': 'C$',
    'CNY': '¥'
  };
  return symbols[divisa.toUpperCase()] || '€';
};

const CurrencyFormatComponent = ({ value, divisa }) => {
  const actualDivisa = divisa || 'EUR';
  return (
    <CurrencyFormat
      value={value}
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
      suffix={` ${getCurrencySymbol(actualDivisa)}`}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

const ImportesRecuperados = ({ importe, divisa }) => (
  <tr className="hover:bg-gray-50">
    <td className="px-4 py-3">
      <div className="flex items-center">
        <div className="text-gray-800">{importe.fecha.substring(0, 10)}</div>
      </div>
    </td>
    <td className="px-4 py-3">
      <div className="text-gray-800">{importe.concept}</div>
    </td>
    <td className="px-4 py-3 text-right text-green-600">
      <CurrencyFormatComponent
        value={importe.importe}
        divisa={divisa}
      />
    </td>
  </tr>
);

const MoneyComeBack = ({ idExpedient }) => {
  const [amount, setAmount] = useState(0);
  const [recoveryDate, setRecoveryDate] = useState("");
  const [expedientId] = useState(idExpedient);
  const [importesRecuperados, setImportesRecuperados] = useState([]);
  const [importeDeuda, setImporteDeuda] = useState("");
  const [concept, setConcept] = useState("");
  const [totalDeudaPendiente, setTotalDeudaPendiente] = useState(0);
  const [expedienteCreado, setExpedienteCreado] = useState("");
  const [divisa, setDivisa] = useState("EUR");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseAPI}/expedients/${idExpedient}`);
        const importes = response.data.importes_recuperados;
        const totalRecuperados = importes.map((t) => t.importe);
        let deudaPendiente = response.data.importe_deuda - totalRecuperados.reduce((a, v) => a + v, 0);
        deudaPendiente = Math.round(deudaPendiente * 100) / 100;
        let importeDeuda = response.data.importe_deuda;
        importeDeuda = Math.round(importeDeuda * 100) / 100;

        setImportesRecuperados(importes);
        setImporteDeuda(importeDeuda);
        setExpedienteCreado(response.data.createdAt);
        setTotalDeudaPendiente(deudaPendiente);
        setDivisa(response.data.divisa || 'EUR');
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [idExpedient]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!amount || !recoveryDate || !concept) {
      return toast.error("Debes rellenar todos los campos");
    }

    const infoAmountRecovery = {
      importe: amount,
      fecha: recoveryDate,
      concept,
    };

    try {
      await axios.put(
        `${baseAPI}/expedients/recoveryamount/${expedientId}`,
        infoAmountRecovery
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Error al añadir el importe recuperado");
    }
  };

  const sortedImportesRecuperados = [...importesRecuperados].sort(
    (a, b) => new Date(b.fecha) - new Date(a.fecha)
  );

  return (
    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Importes Recuperados</h2>
        <div className="mt-2 text-sm text-gray-600">
          Saldo pendiente:{" "}
          <span className="font-semibold text-green-600">
            <CurrencyFormatComponent
              value={totalDeudaPendiente}
              divisa={divisa}
            />
          </span>
        </div>
      </header>
      
      <div className="p-5">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold text-gray-600 bg-gray-50 rounded-sm">
              <tr>
                <th className="px-4 py-3 text-left">Fecha</th>
                <th className="px-4 py-3 text-left">Concepto</th>
                <th className="px-4 py-3 text-right">Importe</th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-200">
              {sortedImportesRecuperados.map((importe) => (
                <ImportesRecuperados 
                  importe={importe} 
                  key={importe._id} 
                  divisa={divisa}
                />
              ))}
              <tr className="bg-gray-50">
                <td className="px-4 py-3">
                  <div className="flex items-center">
                    <div className="text-gray-800">{expedienteCreado.substring(0, 10)}</div>
                  </div>
                </td>
                <td className="px-4 py-3 text-gray-800">Deuda inicial</td>
                <td className="px-4 py-3 text-right text-red-600">
                  -{" "}
                  <CurrencyFormatComponent
                    value={importeDeuda}
                    divisa={divisa}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="mt-8 bg-gray-50 border border-gray-200 rounded-sm p-6">
            <h2 className="text-lg font-semibold text-gray-800 pb-3 border-b border-gray-200 mb-6">
              Añadir Importe Recuperado
            </h2>
            
            <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="amount"
                >
                  Importe
                </label>
                <input
                  id="amount"
                  type="number"
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  placeholder="Importe recuperado"
                  onChange={(e) => setAmount(e.target.value)}
                  step="0.01"
                />
              </div>
              
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="recoveryDate"
                >
                  Fecha de recuperación
                </label>
                <input
                  type="date"
                  id="recoveryDate"
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  onChange={(e) => setRecoveryDate(e.target.value)}
                />
              </div>
              
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="concept"
                >
                  Concepto
                </label>
                <select
                  id="concept"
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  onChange={(e) => setConcept(e.target.value)}
                  defaultValue="-Selecciona una opción-"
                >
                  <option>-Selecciona una opción-</option>
                  <option>Acuerdo extrajudicial</option>
                  <option>Embargo Judicial</option>
                  <option>Compensación</option>
                  <option>Voluntariamente</option>
                  <option>Decreto de adjudicación bien inmueble</option>
                  <option>Decreto de adjudicación bien mueble</option>
                  <option>Mediación</option>
                  <option>Dación en pago</option>
                  <option>Insolvencia</option>
                  <option>Cancelación de deuda</option>
                </select>
              </div>
              
              <div className="md:col-span-3 flex justify-end">
                <button
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
                  type="submit"
                >
                  Añadir importe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoneyComeBack;
