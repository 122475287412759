import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { baseAPI } from "../config";
import { toast } from "react-toastify";
import LogoImage from "../images/claim-up-19.png";
import { 
  EnvelopeIcon,
  ShieldCheckIcon,
  ArrowLeftOnRectangleIcon,
  EnvelopeOpenIcon
} from '@heroicons/react/24/outline';

const ForgetPassword = () => {
  const [usermail, setUsermail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.post(`${baseAPI}/users/resetpassword`, { usermail });
      
      toast.success("Email con instrucciones enviado. Revisa tu bandeja de entrada ✉️", {
        position: "top-right",
        autoClose: 5000,
      });

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.error(error);
      if (error.response?.status === 400) {
        toast.error("Este usuario no existe", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error("Error al enviar el email. Por favor, inténtalo de nuevo.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header Banner */}
      <div className="bg-gradient-to-b from-blue-50 to-white pt-12 pb-6">
        <div className="max-w-lg mx-auto px-4">
          <div className="text-center">
            <img
              src={LogoImage}
              width="180"
              height="180"
              alt="ClaimUp"
              className="mx-auto mb-6"
            />
            <h1 className="text-3xl font-bold text-slate-900 mb-3">
              Recupera tu acceso
            </h1>
            <p className="text-lg text-slate-600">
              Te enviaremos las instrucciones por email
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-8">
        {/* Security Message */}
        <div className="bg-blue-50 border border-blue-100 rounded-xl p-6 mb-8">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <ShieldCheckIcon className="h-6 w-6 text-blue-600" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">
                Proceso seguro de recuperación
              </h3>
              <p className="mt-2 text-sm text-blue-600">
                Por tu seguridad, te enviaremos un enlace único y temporal para restablecer tu contraseña.
                Este enlace caducará en 24 horas.
              </p>
            </div>
          </div>
        </div>

        {/* Reset Form */}
        <div className="bg-white rounded-2xl border border-slate-200 shadow-sm overflow-hidden">
          <div className="p-6">
            <form onSubmit={onSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2" htmlFor="email">
                  Correo electrónico
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <EnvelopeIcon className="h-5 w-5 text-slate-400" />
                  </div>
                  <input
                    id="email"
                    type="email"
                    required
                    value={usermail}
                    onChange={(e) => setUsermail(e.target.value)}
                    className="block w-full pl-10 pr-3 py-2.5 text-slate-900 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors duration-150"
                    placeholder="tu@email.com"
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center items-center px-6 py-3 text-base font-medium rounded-xl text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150 ${
                  isLoading ? "opacity-75 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Enviando instrucciones...
                  </>
                ) : (
                  <>
                    <EnvelopeOpenIcon className="w-5 h-5 mr-2" />
                    Enviar instrucciones
                  </>
                )}
              </button>
            </form>
          </div>
        </div>

        {/* Login CTA */}
        <div className="mt-8 text-center">
          <p className="text-slate-600 mb-4">¿Recordaste tu contraseña?</p>
          <Link
            to="/login"
            className="inline-flex items-center px-6 py-3 border border-slate-200 rounded-xl text-base font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150"
          >
            <ArrowLeftOnRectangleIcon className="w-5 h-5 mr-2" />
            Volver al inicio de sesión
          </Link>
        </div>

        {/* Register CTA */}
        <div className="mt-8 text-center">
          <p className="text-slate-600">
            ¿No tienes cuenta?{" "}
            <Link to="/signup" className="text-blue-600 hover:text-blue-700 font-medium">
              Regístrate gratis
            </Link>
          </p>
        </div>

        {/* Footer */}
        <footer className="mt-12 text-center border-t border-slate-200 pt-6">
          <a 
            href="https://www.claimup.io" 
            className="text-sm text-slate-500 hover:text-slate-700 transition-colors duration-150"
          >
            © {new Date().getFullYear()} ClaimUp · Todos los derechos reservados
          </a>
        </footer>
      </div>
    </div>
  );
};

export default ForgetPassword;
