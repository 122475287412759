import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { baseAPI } from '../../config';
import { toast } from 'react-toastify';

const Note = ({ anotacion, onCompletedTask, activeRole }) => {
  return (
    <div
      className={`rounded-lg border p-5 mb-4 transition-all duration-200 ${
        anotacion.creator_role === 'admin' 
          ? 'bg-purple-50 border-purple-200 hover:bg-purple-100' 
          : 'bg-emerald-50 border-emerald-200 hover:bg-emerald-100'
      }`}
    >
      <header className="flex justify-between items-start mb-3">
        <div>
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium text-gray-800">
              {anotacion.creator_name}
            </span>
            <span className={`text-xs px-2 py-1 rounded-full ${
              anotacion.creator_role === 'admin' 
                ? 'bg-purple-100 text-purple-800' 
                : 'bg-emerald-100 text-emerald-800'
            }`}>
              {anotacion.creator_role}
            </span>
            {anotacion.tarea === 'Pendiente' && (
              <span className="text-xs px-2 py-1 rounded-full bg-amber-100 text-amber-800">
                👨‍💻 Pendiente
              </span>
            )}
            {anotacion.tarea === 'Completada' && (
              <span className="text-xs px-2 py-1 rounded-full bg-green-100 text-green-800">
                ✅ Completada
              </span>
            )}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            {new Date(anotacion.fecha).toLocaleDateString('es-ES', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </div>
        </div>
        {anotacion.tarea === 'Pendiente' && anotacion.creator_role !== activeRole && (
          <div className="flex items-center space-x-2">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                onChange={() => onCompletedTask(anotacion._id)}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div>
              <span className="ml-3 text-sm font-medium text-gray-700">Marcar como completada</span>
            </label>
          </div>
        )}
      </header>
      <div className="text-sm text-gray-700 space-y-2">
        <p className="whitespace-pre-wrap">{anotacion.anotacion}</p>
      </div>
    </div>
  );
};

const NotesExpedient = ({ idExpedient }) => {
  const [anotaciones, setAnotaciones] = useState([]);
  const [anotacion, setAnotacion] = useState('');
  const [usuario, setUsuario] = useState({});
  const [tarea, setTarea] = useState('No hay tarea');

  useEffect(() => {
    const fetchData = async () => {
      const userToken = localStorage.getItem('token');
      const decoded = jwtDecode(userToken);
      setUsuario(decoded);

      try {
        const response = await axios.get(`${baseAPI}/annotations/${idExpedient}`);
        setAnotaciones(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [idExpedient]);

  const handleCompletedTask = async (id) => {
    try {
      await axios.put(`${baseAPI}/annotations/completedtask/${id}`);
      toast.success('Tarea completada', {
        position: 'top-right',
        autoClose: 2000,
      });
      setAnotaciones(anotaciones.map((anotacion) =>
        anotacion._id === id ? { ...anotacion, tarea: 'Completada' } : anotacion
      ));
    } catch (error) {
      console.error(error);
      toast.error('Error al completar la tarea');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!anotacion.trim()) {
      toast.error('Por favor, escribe una anotación');
      return;
    }

    const infoNote = {
      expedient_id: idExpedient,
      anotacion,
      fecha: new Date(),
      user_id: usuario.id,
      creator_name: usuario.name,
      creator_role: usuario.role,
      tarea,
    };

    try {
      await axios.post(`${baseAPI}/annotations/`, infoNote);
      setAnotacion('');
      setTarea('No hay tarea');
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error('Error al añadir la anotación');
    }
  };

  return (
    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Anotaciones</h2>
      </header>
      
      <div className="p-5">
        {anotaciones.length < 1 ? (
          <div className="text-center py-8">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            <p className="mt-2 text-sm text-gray-500">Las anotaciones que añadas aparecerán aquí</p>
          </div>
        ) : (
          <div className="space-y-4">
            {anotaciones.map((nota) => (
              <Note
                key={nota._id}
                anotacion={nota}
                onCompletedTask={handleCompletedTask}
                activeRole={usuario.role}
              />
            ))}
          </div>
        )}

        <form className="mt-8 bg-gray-50 border border-gray-200 rounded-lg p-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Nueva anotación
              </label>
              <textarea
                className="w-full px-4 py-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                rows="4"
                placeholder="Escribe aquí tu anotación..."
                value={anotacion}
                onChange={(e) => setAnotacion(e.target.value)}
              />
            </div>

            <div className="flex items-center">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  onChange={() => setTarea(tarea === 'Pendiente' ? 'No hay tarea' : 'Pendiente')}
                  checked={tarea === 'Pendiente'}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                <span className="ml-3 text-sm font-medium text-gray-700">
                  {usuario.role === 'admin' ? '¿Es una tarea para el cliente?' : '¿Es una tarea para el equipo?'}
                </span>
              </label>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
              >
                <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                Añadir anotación
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NotesExpedient;
