// src/DebtorList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import DebtorRow from "../partials/components/DebtorRow";
import { baseAPI } from "../config";
import { 
  UserGroupIcon, 
  MagnifyingGlassIcon, 
  ExclamationTriangleIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';

// Spinner animado para la carga
const Spinner = () => (
  <div className="flex flex-col items-center justify-center py-12">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
    <p className="mt-4 text-sm text-slate-600">Cargando deudores...</p>
  </div>
);

const DebtorList = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [debtors, setDebtors] = useState([]);
  const [filteredDebtors, setFilteredDebtors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingDebtors, setLoadingDebtors] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});

  const fetchDebtorsWithExpedients = async () => {
    setLoadingDebtors(true);
    setError(null);
    try {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        setError("No se encontró el token de autenticación.");
        return;
      }
      const decoded = jwtDecode(userToken);
      setUser(decoded);
      const user_id = decoded.id;
      const userRole = decoded.role;

      const response = await axios.get(
        `${baseAPI}/debtors/debtors-with-expedients`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "x-user-id": user_id,
            "x-user-role": userRole,
          },
        }
      );
      setDebtors(response.data);
      setFilteredDebtors(response.data);
    } catch (error) {
      console.error("Error fetching debtors:", error);
      setError("No se pudieron cargar los deudores. Por favor, inténtalo de nuevo.");
    } finally {
      setLoadingDebtors(false);
    }
  };

  useEffect(() => {
    fetchDebtorsWithExpedients();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = debtors.filter(
      (debtor) =>
        (debtor.debtornif &&
          debtor.debtornif.toLowerCase().includes(value)) ||
        (debtor.nombre && debtor.nombre.toLowerCase().includes(value))
    );
    setFilteredDebtors(filtered);
  };

  // Función para la fusión de expedientes que llama al backend
  const onMergeExpedients = async (mainExpedient, mergeData) => {
    try {
      const response = await axios.post(
        `${baseAPI}/expedients/merge-expedients`,
        {
          mainExpedient,
          expedientsToCancel: mergeData.expedientsToCancel,
        }
      );
      console.log("Respuesta del backend:", response.data);
    } catch (error) {
      console.error("Error al fusionar expedientes:", error);
      throw error;
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-slate-50">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-grow p-6">
          <div className="max-w-6xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:items-center sm:justify-between mb-8">
              <div className="mb-4 sm:mb-0">
                <div className="flex items-center">
                  <div className="mr-4">
                    <UserGroupIcon className="w-12 h-12 text-blue-600" />
                  </div>
                  <div>
                    <h1 className="text-2xl md:text-3xl font-bold text-slate-800">
                      Listado de Deudores
                    </h1>
                    <p className="mt-1.5 text-sm text-slate-600">
                      Gestiona y visualiza todos los deudores registrados
                    </p>
                  </div>
                </div>
              </div>

              {/* Quick Stats */}
              <div className="bg-white px-4 py-3 rounded-xl border border-slate-200 flex items-center gap-4">
                <div className="text-center px-4 border-r border-slate-200">
                  <div className="text-2xl font-bold text-slate-800">{debtors.length}</div>
                  <div className="text-xs text-slate-500">Total Deudores</div>
                </div>
                <div className="text-center px-4">
                  <div className="text-2xl font-bold text-slate-800">{filteredDebtors.length}</div>
                  <div className="text-xs text-slate-500">Mostrando</div>
                </div>
              </div>
            </div>

            {/* Search Bar */}
            <div className="relative mb-8">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-slate-400" />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Buscar por NIF o nombre de deudor..."
                className="w-full pl-11 pr-4 py-3 text-sm bg-white border border-slate-200 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150"
              />
            </div>

            {/* Content */}
            {error ? (
              <div className="bg-amber-50 border border-amber-200 rounded-xl p-6 text-center">
                <ExclamationTriangleIcon className="h-12 w-12 text-amber-500 mx-auto mb-4" />
                <p className="text-amber-800 mb-4">{error}</p>
                <button
                  onClick={fetchDebtorsWithExpedients}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-amber-700 bg-amber-100 hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition-colors duration-150"
                >
                  <ArrowPathIcon className="h-4 w-4 mr-2" />
                  Reintentar
                </button>
              </div>
            ) : loadingDebtors ? (
              <Spinner />
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredDebtors.length > 0 ? (
                  filteredDebtors.map((debtor) => (
                    <DebtorRow
                      key={debtor._id}
                      debtor={debtor}
                      onMergeExpedients={onMergeExpedients}
                      userRole={user.role}
                    />
                  ))
                ) : (
                  <div className="col-span-full bg-white rounded-xl border border-slate-200 p-12 text-center">
                    <MagnifyingGlassIcon className="h-12 w-12 text-slate-400 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-slate-900 mb-2">
                      No se encontraron deudores
                    </h3>
                    <p className="text-sm text-slate-500">
                      No hay deudores que coincidan con tu búsqueda. Intenta con otros términos.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default DebtorList;
