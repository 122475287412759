import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { baseAPI } from "../config";
import { toast } from "react-toastify";
import LogoImage from "../images/claim-up-19.png";
import { 
  UserPlusIcon,
  EnvelopeIcon,
  KeyIcon,
  PhoneIcon,
  ShieldCheckIcon,
  DocumentCheckIcon,
  ClockIcon,
  ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline';

const Signup = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    usermail: "",
    userpassword: "",
    userphone: "",
    userrole: "client",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const user = {
        usermail: formData.usermail,
        userpassword: formData.userpassword,
        userphone: formData.userphone,
        userrole: formData.userrole,
      };

      const response = await axios.post(`${baseAPI}/users/add`, user);
      
      if (response.status === 200) {
        const loginResponse = await axios.post(`${baseAPI}/users/login`, {
          usermail: formData.usermail,
          userpassword: formData.userpassword,
        });

        if (loginResponse.status === 200) {
          const token = loginResponse.data.token;
          const userid = loginResponse.data.userId;
          localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = token;

          toast.success("¡Cuenta creada con éxito! 🎉", {
            position: "top-right",
            autoClose: 2000,
          });

          setTimeout(() => {
            navigate(`/complete-user-profile/${userid}`);
          }, 1000);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al crear la cuenta. Por favor, inténtalo de nuevo.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header Banner */}
      <div className="bg-gradient-to-b from-blue-50 to-white pt-12 pb-6">
        <div className="max-w-2xl mx-auto px-4">
          <div className="text-center">
            <img
              src={LogoImage}
              width="180"
              height="180"
              alt="ClaimUp"
              className="mx-auto mb-6"
            />
            <h1 className="text-3xl font-bold text-slate-900 mb-3">
              Únete a ClaimUp
            </h1>
            <p className="text-lg text-slate-600">
              Comienza a gestionar tus reclamaciones de forma profesional
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-2xl mx-auto px-4 py-8">
        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          <div className="bg-white p-6 rounded-xl border border-slate-200">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-4">
              <ShieldCheckIcon className="w-6 h-6 text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold text-slate-900 mb-2">Seguridad Garantizada</h3>
            <p className="text-sm text-slate-600">Tus datos siempre protegidos y seguros</p>
          </div>
          <div className="bg-white p-6 rounded-xl border border-slate-200">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-4">
              <DocumentCheckIcon className="w-6 h-6 text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold text-slate-900 mb-2">Gestión Eficiente</h3>
            <p className="text-sm text-slate-600">Control total de tus expedientes</p>
          </div>
          <div className="bg-white p-6 rounded-xl border border-slate-200">
            <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-4">
              <ClockIcon className="w-6 h-6 text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold text-slate-900 mb-2">Ahorra Tiempo</h3>
            <p className="text-sm text-slate-600">Automatiza tus procesos de cobro</p>
          </div>
        </div>

        {/* Signup Form */}
        <div className="bg-white rounded-2xl border border-slate-200 shadow-sm overflow-hidden">
          <div className="p-6">
            <form onSubmit={onSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-2" htmlFor="email">
                    Correo electrónico
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <EnvelopeIcon className="h-5 w-5 text-slate-400" />
                    </div>
                    <input
                      type="email"
                      name="usermail"
                      id="email"
                      required
                      value={formData.usermail}
                      onChange={handleChange}
                      className="block w-full pl-10 pr-3 py-2.5 text-slate-900 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors duration-150"
                      placeholder="tu@email.com"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-slate-700 mb-2" htmlFor="phone">
                    Teléfono
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <PhoneIcon className="h-5 w-5 text-slate-400" />
                    </div>
                    <input
                      type="tel"
                      name="userphone"
                      id="phone"
                      required
                      value={formData.userphone}
                      onChange={handleChange}
                      className="block w-full pl-10 pr-3 py-2.5 text-slate-900 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors duration-150"
                      placeholder="+34 600 000 000"
                    />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2" htmlFor="password">
                  Contraseña
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <KeyIcon className="h-5 w-5 text-slate-400" />
                  </div>
                  <input
                    type="password"
                    name="userpassword"
                    id="password"
                    required
                    value={formData.userpassword}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-3 py-2.5 text-slate-900 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors duration-150"
                    placeholder="••••••••"
                  />
                </div>
                <p className="mt-2 text-sm text-slate-500">
                  Mínimo 8 caracteres, incluyendo mayúsculas y números
                </p>
              </div>

              <div className="flex items-center">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  required
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-slate-300 rounded"
                />
                <label htmlFor="terms" className="ml-2 block text-sm text-slate-600">
                  Acepto los{" "}
                  <a href="www.claimup.io" className="text-blue-600 hover:text-blue-700">
                    términos y condiciones
                  </a>{" "}
                  y la{" "}
                  <a href="www.claimup.io" className="text-blue-600 hover:text-blue-700">
                    política de privacidad
                  </a>
                </label>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center items-center px-6 py-3 text-base font-medium rounded-xl text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150 ${
                  isLoading ? "opacity-75 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Creando cuenta...
                  </>
                ) : (
                  <>
                    <UserPlusIcon className="w-5 h-5 mr-2" />
                    Crear cuenta gratuita
                  </>
                )}
              </button>
            </form>
          </div>
        </div>

        {/* Login CTA */}
        <div className="mt-8 text-center">
          <p className="text-slate-600 mb-4">¿Ya tienes una cuenta?</p>
          <Link
            to="/login"
            className="inline-flex items-center px-6 py-3 border border-slate-200 rounded-xl text-base font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150"
          >
            <ArrowLeftOnRectangleIcon className="w-5 h-5 mr-2" />
            Iniciar sesión
          </Link>
        </div>

        {/* Footer */}
        <footer className="mt-12 text-center border-t border-slate-200 pt-6">
          <a 
            href="https://www.claimup.io" 
            className="text-sm text-slate-500 hover:text-slate-700 transition-colors duration-150"
          >
            © {new Date().getFullYear()} ClaimUp · Todos los derechos reservados
          </a>
        </footer>
      </div>
    </div>
  );
};

export default Signup;
