import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import { focusHandling } from "cruip-js-toolkit";

// Import pages
import Dashboard from "./pages/dashboard";
import ExpedientList from "./pages/expedientList";
import InteractionsList from "./pages/interactionsList";
import Login from "./pages/login";
import Signup from "./pages/signup";
import UsersList from "./pages/users";
import CreateExpedients from "./pages/createExpedient";
import ExpedientView from "./pages/expedientView";
import Profile from "./pages/profile";
import Home from "./pages/landing";
import ImportExpedients from "./pages/importExpedients";
import ForgetPassword from "./pages/forgetPassword";
import ResetPassword from "./pages/resetPassword";
import SuccesPaymentDeptor from "./pages/succesPaymentDebtor";
import CompletUserProfile from "./pages/completUserProfile";
import ChatDebtor from "./pages/chatDebtors";
import GenerateLinkStripeToDebtor from "./pages/linkStripeDebtorGenerator";
import PreivacyPolicy from "./pages/privacyPolicy";
import Task from "./pages/tasks";
import FocusExpedientsList from "./pages/focusExpedients";
import RecoveredAmountList from "./pages/recoveredAmountsList";
import DebtorList from "./pages/debtorsList";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/chat-debtor/:id" element={<ChatDebtor />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/users" element={<UsersList />} />
      <Route path="/create-expedient" element={<CreateExpedients />} />
      <Route path="/view/:id" element={<ExpedientView />} />
      <Route path="/profile/:id" element={<Profile />} />
      <Route path="/expedients" element={<ExpedientList />} />
      <Route path="/interactions" element={<InteractionsList />} />
      <Route path="/focus-expedients" element={<FocusExpedientsList />} />
      <Route path="/recovered-amounts" element={<RecoveredAmountList />} />
      <Route path="/import-expedients" element={<ImportExpedients />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/reset-password/:id" element={<ResetPassword />} />
      <Route path="/success-payment-debtor" element={<SuccesPaymentDeptor />} />
      <Route path="/complete-user-profile/:id" element={<CompletUserProfile />} />
      <Route path="/generate-link-stripe-sms/:id" element={<GenerateLinkStripeToDebtor />} />
      <Route path="/generate-link-stripe-email/:id" element={<GenerateLinkStripeToDebtor />} />
      <Route path="/generate-link-stripe-chat/:id" element={<GenerateLinkStripeToDebtor />} />
      <Route path="/privacy-policy" element={<PreivacyPolicy />} />
      <Route path="/tasks" element={<Task />} />
      <Route path="/debtors-with-expedients" element={<DebtorList />} />
    </Routes>
  );
}

export default App;
