import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { baseAPI } from "../../config";
import UpdateDebtor from "./UpdateDebtorModal";
import { jwtDecode } from "jwt-decode";

const Debtors = ({ idExpedient }) => {
  const [deudores, setDeudores] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [crearDeudores, setCrearDeudores] = useState(false);
  const [isWhatsappModalOpen, setIsWhatsappModalOpen] = useState(false);
  const [whatsappMessage, setWhatsappMessage] = useState("");
  const [selectedDebtorId, setSelectedDebtorId] = useState(null);
  const [nuevoDeudor, setNuevoDeudor] = useState({
    nombre: "",
    domicilio: "",
    ciudad: "",
    cp: "",
    telefono: "",
    mail: "",
  });

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const decoded = jwtDecode(userToken);
    setUserRole(decoded.role);

    const fetchExpediente = async () => {
      try {
        const response = await axios.get(
          `${baseAPI}/expedients/${idExpedient}`
        );
        setDeudores(response.data.deudores_id || []);
      } catch (error) {
        console.error("Error fetching expediente:", error);
      }
    };

    fetchExpediente();
  }, [idExpedient]);

  const handleNuevoDeudorChange = (e) => {
    const { name, value } = e.target;
    setNuevoDeudor({ ...nuevoDeudor, [name]: value });
  };

  const handleSubmitNuevoDeudor = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${baseAPI}/expedients/adddebtor`, {
        ...nuevoDeudor,
        expedient_id: idExpedient,
      });
      toast.success("Deudor añadido correctamente 😊");
      window.location.reload();
    } catch (error) {
      console.error("Error añadiendo deudor:", error);
      toast.error("Error al añadir el deudor 😢");
    }
  };

  const openWhatsappModal = (debtorId) => {
    setSelectedDebtorId(debtorId);
    setIsWhatsappModalOpen(true);
  };

  const handleSendWhatsapp = async (e) => {
    e.preventDefault();
    try {
      const userToken = localStorage.getItem("token");
      const decoded = jwtDecode(userToken);
      await axios.post(`${baseAPI}/actions/sendwhatsapp`, {
        debtorId: selectedDebtorId,
        expedientId: idExpedient,
        userSesion: decoded,
        message: whatsappMessage,
      });
      toast.success("WhatsApp enviado correctamente 📲");
      setWhatsappMessage("");
      setIsWhatsappModalOpen(false);
    } catch (error) {
      console.error("Error enviando el WhatsApp:", error);
      toast.error("Error al enviar el WhatsApp 😞");
    }
  };

  const handleEmailToDebtor = async (debtorId) => {
    if (window.confirm("¿Seguro que quieres enviar el mail?")) {
      try {
        const userToken = localStorage.getItem("token");
        const decoded = jwtDecode(userToken);
        await axios.post(`${baseAPI}/actions/sendEmailToDebtor`, {
          debtorId,
          expedientId: idExpedient,
          userSesion: decoded,
        });
        toast.success("Email enviado correctamente ✉️");
      } catch (error) {
        console.error("Error enviando el email:", error);
        toast.error("Error al enviar el email 😞");
      }
    }
  };

  const handleSmsToDebtor = async (debtorId) => {
    if (window.confirm("¿Seguro que quieres enviar un SMS?")) {
      try {
        const userToken = localStorage.getItem("token");
        const decoded = jwtDecode(userToken);
        await axios.post(`${baseAPI}/actions/sendsmstodebtor`, {
          debtorId,
          expedientId: idExpedient,
          userSesion: decoded,
        });
        toast.success("SMS enviado correctamente 📱");
      } catch (error) {
        console.error("Error enviando el SMS:", error);
        toast.error("Error al enviar el SMS 😞");
      }
    }
  };

  const handleCallToDebtor = async (debtorId) => {
    if (window.confirm("¿Seguro que quieres realizar una llamada?")) {
      try {
        const userToken = localStorage.getItem("token");
        const decoded = jwtDecode(userToken);
        await axios.post(`${baseAPI}/actions/calltodebtor`, {
          debtorId,
          expedientId: idExpedient,
          userSesion: decoded,
        });
        toast.success("Llamada realizada correctamente 📞");
      } catch (error) {
        console.error("Error realizando la llamada:", error);
        toast.error("Error al realizar la llamada 😞");
      }
    }
  };

  return (
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Deudores</h2>
      </header>
      
      <div className="p-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {deudores.map((deudor) => (
            <div
              key={deudor._id}
              className="bg-gray-50 border border-gray-200 rounded-sm shadow-sm hover:shadow-md transition-shadow duration-150"
            >
              <div className="p-4">
                <div className="flex items-center justify-between mb-3">
                  <h3 className="text-sm font-semibold text-gray-800 truncate">
                    {deudor.debtorname}
                  </h3>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    Activo
                  </span>
                </div>
                
                <div className="space-y-2">
                  <div className="flex items-center text-sm text-gray-600">
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    {deudor.debtorphone}
                  </div>
                  <div className="flex items-center text-sm text-gray-600">
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    {deudor.debtoradress}, {deudor.debtorcity}, {deudor.debtorzip}
                  </div>
                </div>
              </div>

              {userRole === "admin" && (
                <div className="border-t border-gray-200 p-4 bg-gray-50">
                  <div className="grid grid-cols-2 gap-2">
                    <button
                      onClick={() => handleEmailToDebtor(deudor._id)}
                      className="inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      Email
                    </button>
                    <button
                      onClick={() => handleSmsToDebtor(deudor._id)}
                      className="inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                      </svg>
                      SMS
                    </button>
                    <button
                      onClick={() => handleCallToDebtor(deudor._id)}
                      className="inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                      </svg>
                      Llamar
                    </button>
                    <UpdateDebtor idDebtor={deudor._id} />
                    <button
                      onClick={() => openWhatsappModal(deudor._id)}
                      className="inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                      </svg>
                      WhatsApp
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Modal de WhatsApp */}
        {isWhatsappModalOpen && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <div className="bg-white p-6">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4">
                    Enviar WhatsApp Personalizado
                  </h3>
                  <form onSubmit={handleSendWhatsapp}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Mensaje:
                      </label>
                      <textarea
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                        rows="4"
                        value={whatsappMessage}
                        onChange={(e) => setWhatsappMessage(e.target.value)}
                        required
                      ></textarea>
                    </div>
                    <div className="flex justify-end space-x-2">
                      <button
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => setIsWhatsappModalOpen(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Enviar WhatsApp
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Formulario de nuevo deudor */}
        {!crearDeudores ? (
          <div className="mt-6">
            <button
              onClick={() => setCrearDeudores(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
              </svg>
              Añadir deudor
            </button>
          </div>
        ) : (
          <div className="mt-6 bg-gray-50 border border-gray-200 rounded-sm p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Añadir un nuevo Deudor</h3>
            <form onSubmit={handleSubmitNuevoDeudor}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {["nombre", "domicilio", "ciudad", "cp", "telefono", "mail"].map(
                  (campo) => (
                    <div key={campo}>
                      <label className="block text-sm font-medium text-gray-700 capitalize">
                        {campo}
                      </label>
                      <input
                        type="text"
                        name={campo}
                        value={nuevoDeudor[campo]}
                        onChange={handleNuevoDeudorChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                        required
                      />
                    </div>
                  )
                )}
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setCrearDeudores(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Guardar deudor
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Debtors;
