import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import ExpedientsFilters from "./ExpedientsFilters";
import Pagination from "../../components/Pagination";

const getStatusStyle = (status) => {
  switch (status) {
    case "On going":
      return "bg-yellow-100 text-yellow-800";
    case "Email opened":
      return "bg-green-100 text-green-800";
    case "Hot":
      return "bg-red-100 text-red-800";
    case "Cold":
      return "bg-yellow-100 text-yellow-800";
    case "Fallido":
      return "bg-red-100 text-red-800";
    case "Ilocalizable":
      return "bg-red-100 text-red-800";
    case "Sin datos de contacto":
      return "bg-red-100 text-red-800";
    case "Cancelled":
      return "bg-red-100 text-red-800";
    case "Dispute":
      return "bg-red-100 text-red-800";
    case "Burofax Enviado":
      return "bg-yellow-100 text-yellow-800";
    case "Burofax con resultado negativo":
      return "bg-red-100 text-red-800";
    case "Burofax con resultado positivo":
      return "bg-green-100 text-green-800";
    case "Demanda pendiente":
      return "bg-yellow-100 text-yellow-800";
    case "Demanda preparada":
      return "bg-yellow-100 text-yellow-800";
    case "Demanda presentada":
      return "bg-yellow-100 text-yellow-800";
    case "Demanda Admitida":
      return "bg-green-100 text-green-800";
    case "Demanda No Admitida":
      return "bg-red-100 text-red-800";
    case "Oposición del Deudor":
      return "bg-red-100 text-red-800";
    case "Pendiente Requerimiento":
      return "bg-yellow-100 text-yellow-800";
    case "Requerimiento Judicial Positivo":
      return "bg-green-100 text-green-800";
    case "Pendiente Ejecucion":
      return "bg-red-100 text-red-800";
    case "Sentencia Estimatoria":
      return "bg-green-100 text-green-800";
    case "Sentencia Desestimatoria":
      return "bg-red-100 text-red-800";
    case "Sentencia Parcial":
      return "bg-yellow-100 text-yellow-800";
    case "Cobrado":
      return "bg-green-100 text-green-800";
    case "Cobrado Judicialmente":
      return "bg-green-100 text-green-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

const getCurrencySymbol = (divisa) => {
  if (!divisa) return '€';
  
  const symbols = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
    'JPY': '¥',
    'CHF': 'CHF',
    'AUD': 'A$',
    'CAD': 'C$',
    'CNY': '¥'
  };
  return symbols[divisa.toUpperCase()] || '€';
};

const CurrencyFormatComponent = ({ value, divisa }) => {
  const actualDivisa = divisa || 'EUR';
  console.log('CurrencyFormat - Received divisa:', divisa);
  console.log('CurrencyFormat - Using divisa:', actualDivisa);
  
  return (
    <CurrencyFormat
      value={value}
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
      suffix={` ${getCurrencySymbol(actualDivisa)}`}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

const Expedients = ({ createdAt, expedient, viewExpedient, role }) => {
  console.log('Full expedient:', expedient); // Debug full expedient object
  const { user_id, deudores_id, importe_deuda, estado, _id, divisa } = expedient;
  console.log('Desestructured divisa:', divisa); // Debug divisa after destructuring
  
  const formattedDate = createdAt ? moment(createdAt).format("DD/MM/YYYY") : "No Date";

  return (
    <tr className="hover:bg-gray-50 transition-colors duration-150">
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="text-sm text-gray-800">{formattedDate}</div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="text-sm text-gray-800">
          {user_id.usermail ? (
            user_id.usermail
          ) : (
            <span className="text-red-500" role="img" aria-label="no mail">
              <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
          )}
        </div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-800">{deudores_id[0].debtorname}</div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-800">
          <CurrencyFormatComponent 
            value={importe_deuda} 
            divisa={divisa}
          />
        </div>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <span className={`px-2.5 py-0.5 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(estado)}`}>
          {estado}
        </span>
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <a
            href={`/view/${_id}`}
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:text-blue-800 transition-colors duration-150"
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
          </a>
        </div>
      </td>
    </tr>
  );
};

const ExpedientsAdmin = ({ searchText }) => {
  const [expedients, setExpedients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 20;
  const [role, setRole] = useState("");
  const [selectedEstado, setSelectedEstado] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [sortField] = useState("importe_deuda");
  const [sortOrder, setSortOrder] = useState("desc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const exportToExcel = async () => {
    try {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        toast.error("No estás autenticado.", {
          position: "top-right",
          autoClose: 5000,
        });
        return;
      }

      const decoded = jwtDecode(userToken);

      const params = {
        decoded: JSON.stringify(decoded),
        estado: selectedEstado,
        cliente: selectedUser,
        search: searchText,
        sortField,
        sortOrder,
      };

      const response = await axios.get(`${baseAPI}/expedients/export`, {
        params,
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "expedients.xlsx");

      toast.success("Exportación exitosa!", {
        position: "top-right",
        autoClose: 5000,
      });
    } catch (error) {
      console.error(error);
      toast.error("Error al exportar los expedientes.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    const fetchExpedients = async () => {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        console.log("no hay token");
        return;
      }

      const decoded = jwtDecode(userToken);
      setRole(decoded.role);

      try {
        const params = {
          decoded: JSON.stringify(decoded),
          page: currentPage,
          limit: itemsPerPage,
          search: searchText,
          estado: selectedEstado,
          cliente: selectedUser,
          sortField,
          sortOrder,
          startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null
        };

        const response = await axios.get(`${baseAPI}/expedients`, { params });
        setExpedients(response.data.expedients);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.log(error);
      }
    };

    fetchExpedients();
  }, [
    currentPage,
    searchText,
    selectedEstado,
    selectedUser,
    itemsPerPage,
    sortField,
    sortOrder,
    startDate,
    endDate
  ]);

  return (
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-3 sm:space-y-0">
          <h2 className="font-semibold text-slate-800">
            <div className="flex items-center">
              <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              Reclamaciones
            </div>
          </h2>
          <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-3">
            <button
              onClick={() => setShowFilter(!showFilter)}
              className="inline-flex items-center w-full sm:w-auto justify-center px-3 py-2 text-sm font-medium text-slate-700 bg-white border border-slate-200 rounded-md hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-150"
            >
              <svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
              </svg>
              {showFilter ? "Ocultar Filtros" : "Mostrar Filtros"}
            </button>
            <button
              onClick={exportToExcel}
              className="inline-flex items-center w-full sm:w-auto justify-center px-3 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-150"
            >
              <svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
              Exportar
            </button>
          </div>
        </div>
      </header>

      <div className="p-5">
        <ExpedientsFilters
          showFilter={showFilter}
          selectedEstado={selectedEstado}
          setSelectedEstado={setSelectedEstado}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          role={role}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fecha Creación
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Cliente
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Deudor
                </th>
                <th
                  onClick={toggleSortOrder}
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700"
                >
                  <div className="flex items-center">
                    Importe Deuda
                    <svg
                      className={`w-4 h-4 ml-1 transform transition-transform duration-200 ${
                        sortOrder === "asc" ? "rotate-180" : ""
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  </div>
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Estado
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {expedients.map((expedient) => (
                <Expedients
                  key={expedient._id}
                  createdAt={expedient.createdAt}
                  expedient={expedient}
                  role={role}
                />
              ))}
            </tbody>
          </table>

          {expedients.length === 0 && (
            <div className="text-center py-12">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">No hay expedientes</h3>
              <p className="mt-1 text-sm text-gray-500">Crea uno nuevo para comenzar.</p>
            </div>
          )}

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpedientsAdmin;
