import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AccountPanel from "../partials/components/AccountPanel";
import { UserCircleIcon, ShieldCheckIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

function Profile() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { id: userId } = useParams();

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        
        <div className="flex flex-col flex-1">
          <main className="flex-1">
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="max-w-5xl mx-auto mb-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="mb-4 sm:mb-0">
                    <div className="flex items-center">
                      <div className="mr-4">
                        <UserCircleIcon className="w-12 h-12 text-blue-600" />
                      </div>
                      <div>
                        <h1 className="text-2xl md:text-3xl font-bold text-slate-800">
                          Perfil de Usuario
                        </h1>
                        <p className="mt-1.5 text-sm text-slate-600">
                          Gestiona tu información personal y datos de facturación
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  {/* Quick Stats */}
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-4 rounded-xl border border-slate-200 flex items-center">
                      <ShieldCheckIcon className="w-8 h-8 text-blue-600 mr-3" />
                      <div>
                        <div className="text-sm font-medium text-slate-600">Seguridad</div>
                        <div className="text-lg font-semibold text-slate-800">Verificado</div>
                      </div>
                    </div>
                    <div className="bg-white p-4 rounded-xl border border-slate-200 flex items-center">
                      <DocumentTextIcon className="w-8 h-8 text-blue-600 mr-3" />
                      <div>
                        <div className="text-sm font-medium text-slate-600">Documentos</div>
                        <div className="text-lg font-semibold text-slate-800">Al día</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div className="max-w-5xl mx-auto">
                <AccountPanel userId={userId} />
              </div>
            </div>
          </main>

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Profile;
