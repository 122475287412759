import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseAPI } from "../config";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import LogoImage from "../images/claim-up-19.png";
import { 
  KeyIcon,
  ShieldCheckIcon,
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline';

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    usermail: "",
    password: "",
    confirmPassword: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id: token } = useParams();

  useEffect(() => {
    try {
      const decoded = jwtDecode(token);
      setFormData(prev => ({ ...prev, usermail: decoded.usermail }));
    } catch (error) {
      console.error('Token inválido');
      navigate('/login');
    }
  }, [token, navigate]);

  const validatePassword = () => {
    if (formData.password.length < 8) {
      toast.error("La contraseña debe tener al menos 8 caracteres");
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword()) return;
    
    setIsLoading(true);

    try {
      await axios.post(`${baseAPI}/users/newpassword`, {
        usermail: formData.usermail,
        password: formData.password
      });

      toast.success("¡Contraseña actualizada con éxito! 🔐", {
        position: "top-right",
        autoClose: 3000,
      });

      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      console.error(error);
      toast.error("Error al actualizar la contraseña. Por favor, inténtalo de nuevo.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header Banner */}
      <div className="bg-gradient-to-b from-blue-50 to-white pt-12 pb-6">
        <div className="max-w-lg mx-auto px-4">
          <div className="text-center">
            <img
              src={LogoImage}
              width="180"
              height="180"
              alt="ClaimUp"
              className="mx-auto mb-6"
            />
            <h1 className="text-3xl font-bold text-slate-900 mb-3">
              Establece tu nueva contraseña
            </h1>
            <p className="text-lg text-slate-600">
              Crea una contraseña segura para proteger tu cuenta
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-8">
        {/* Password Requirements */}
        <div className="bg-blue-50 border border-blue-100 rounded-xl p-6 mb-8">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <ShieldCheckIcon className="h-6 w-6 text-blue-600" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">
                Requisitos de seguridad
              </h3>
              <ul className="mt-2 text-sm text-blue-600 space-y-1">
                <li className="flex items-center">
                  <CheckCircleIcon className="h-4 w-4 mr-2" />
                  Mínimo 8 caracteres
                </li>
                <li className="flex items-center">
                  <CheckCircleIcon className="h-4 w-4 mr-2" />
                  Al menos una mayúscula
                </li>
                <li className="flex items-center">
                  <CheckCircleIcon className="h-4 w-4 mr-2" />
                  Al menos un número
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Reset Form */}
        <div className="bg-white rounded-2xl border border-slate-200 shadow-sm overflow-hidden">
          <div className="p-6">
            <form onSubmit={onSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2" htmlFor="password">
                  Nueva contraseña
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <KeyIcon className="h-5 w-5 text-slate-400" />
                  </div>
                  <input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    required
                    value={formData.password}
                    onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                    className="block w-full pl-10 pr-10 py-2.5 text-slate-900 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors duration-150"
                    placeholder="••••••••"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-slate-400 hover:text-slate-600" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-slate-400 hover:text-slate-600" />
                    )}
                  </button>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2" htmlFor="confirm-password">
                  Confirmar contraseña
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <KeyIcon className="h-5 w-5 text-slate-400" />
                  </div>
                  <input
                    id="confirm-password"
                    type={showPassword ? "text" : "password"}
                    required
                    value={formData.confirmPassword}
                    onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                    className="block w-full pl-10 pr-10 py-2.5 text-slate-900 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors duration-150"
                    placeholder="••••••••"
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center items-center px-6 py-3 text-base font-medium rounded-xl text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150 ${
                  isLoading ? "opacity-75 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Actualizando contraseña...
                  </>
                ) : (
                  <>
                    <ShieldCheckIcon className="w-5 h-5 mr-2" />
                    Establecer nueva contraseña
                  </>
                )}
              </button>
            </form>
          </div>
        </div>

        {/* Login CTA */}
        <div className="mt-8 text-center">
          <p className="text-slate-600 mb-4">¿Ya tienes tu contraseña?</p>
          <Link
            to="/login"
            className="inline-flex items-center px-6 py-3 border border-slate-200 rounded-xl text-base font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150"
          >
            <ArrowLeftOnRectangleIcon className="w-5 h-5 mr-2" />
            Ir al inicio de sesión
          </Link>
        </div>

        {/* Footer */}
        <footer className="mt-12 text-center border-t border-slate-200 pt-6">
          <a 
            href="https://www.claimup.io" 
            className="text-sm text-slate-500 hover:text-slate-700 transition-colors duration-150"
          >
            © {new Date().getFullYear()} ClaimUp · Todos los derechos reservados
          </a>
        </footer>
      </div>
    </div>
  );
};

export default ResetPassword;
