// src/partials/components/DebtorRow.js
import React, { useState, useEffect } from "react";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

const getCurrencySymbol = (divisa) => {
  if (!divisa) return '€';
  
  const symbols = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
    'JPY': '¥',
    'CHF': 'CHF',
    'AUD': 'A$',
    'CAD': 'C$',
    'CNY': '¥'
  };
  return symbols[divisa.toUpperCase()] || '€';
};

const CurrencyFormatComponent = ({ value, divisa }) => {
  const actualDivisa = divisa || 'EUR';
  return (
    <CurrencyFormat
      value={value}
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
      suffix={` ${getCurrencySymbol(actualDivisa)}`}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

const getStatusStyle = (status) => {
  switch (status) {
    case "On going":
      return "bg-yellow-100 text-yellow-800";
    case "Cobrado":
    case "Cobrado Judicialmente":
      return "bg-green-100 text-green-800";
    case "Dispute":
    case "Fallido":
    case "Cancelled":
      return "bg-red-100 text-red-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

const Modal = ({ isOpen, onClose, debtor, onMergeExpedients, userRole }) => {
  const [selectedExpedients, setSelectedExpedients] = useState([]);
  const [mainExpedient, setMainExpedient] = useState("");
  const [showMergeSummary, setShowMergeSummary] = useState(false);
  const [mergeSummary, setMergeSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedExpedient, setExpandedExpedient] = useState(null);

  // Calcula el total de deuda de todos los expedientes
  const totalDebt = debtor.expedientes.reduce(
    (sum, exp) => sum + exp.importe_deuda,
    0
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedExpedients([]);
      setMainExpedient("");
      setShowMergeSummary(false);
      setMergeSummary(null);
      setLoading(false);
      setExpandedExpedient(null);
    }
  }, [isOpen]);

  const toggleExpedientSelection = (id) => {
    setSelectedExpedients((prev) =>
      prev.includes(id)
        ? prev.filter((expId) => expId !== id)
        : [...prev, id]
    );
  };

  const handlePrepareMerge = () => {
    if (selectedExpedients.length < 2) {
      alert("Selecciona al menos dos expedientes para fusionar.");
      return;
    }
    generateMergeSummary();
    setShowMergeSummary(true);
  };

  const generateMergeSummary = () => {
    const selectedData = debtor.expedientes.filter((exp) =>
      selectedExpedients.includes(exp._id)
    );
    const totalImporteDeuda = selectedData.reduce(
      (sum, exp) => sum + exp.importe_deuda,
      0
    );
    setMergeSummary({
      expedientsToCancel: selectedExpedients.filter(
        (id) => id !== mainExpedient
      ),
      importeTotal: totalImporteDeuda,
    });
  };

  const confirmMerge = async () => {
    if (!mainExpedient) {
      alert("Selecciona el expediente principal.");
      return;
    }
    setLoading(true);
    try {
      await onMergeExpedients(mainExpedient, {
        expedientsToCancel: selectedExpedients.filter(
          (id) => id !== mainExpedient
        ),
      });
      alert("Expedientes fusionados correctamente.");
      onClose();
    } catch (error) {
      console.error("Error al fusionar expedientes:", error);
      alert("Ocurrió un error al fusionar los expedientes.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto p-4 bg-slate-900/60 backdrop-blur-sm">
          <div className="bg-white rounded-xl shadow-xl w-full max-w-4xl mx-auto max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              {/* Header del Modal */}
              <div className="flex justify-between items-center border-b border-slate-200 pb-4 mb-6">
                <div>
                  <h2 className="text-2xl font-bold text-slate-800 flex items-center gap-2">
                    <svg className="w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    {debtor.nombre}
                  </h2>
                  <p className="text-slate-500 mt-1">Total Deuda: <CurrencyFormatComponent value={totalDebt} divisa={debtor.expedientes[0]?.divisa} /></p>
                </div>
                <button
                  onClick={onClose}
                  className="text-slate-400 hover:text-slate-600 p-2 rounded-lg hover:bg-slate-100 transition-colors duration-150"
                >
                  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Vista de selección de expedientes */}
              {!showMergeSummary && (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {debtor.expedientes.map((exp) => (
                      <div
                        key={exp._id}
                        className="bg-white rounded-lg border border-slate-200 shadow-sm hover:shadow transition-shadow duration-150"
                      >
                        <div className="p-4">
                          {/* Estado y Referencia */}
                          <div className="flex flex-col space-y-2">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(exp.estado)}`}>
                              {exp.estado}
                            </span>
                            <div className="flex items-center gap-2">
                              <input
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 rounded border-slate-300 focus:ring-blue-500"
                                checked={selectedExpedients.includes(exp._id)}
                                onChange={() => toggleExpedientSelection(exp._id)}
                              />
                              <a
                                href={`/view/${exp._id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 text-xs font-medium hover:underline truncate"
                              >
                                {exp._id}
                              </a>
                            </div>
                          </div>

                          {/* Importe y Fecha */}
                          <div className="mt-3 pt-3 border-t border-slate-200">
                            <p className="text-xl font-bold text-slate-800">
                              <CurrencyFormatComponent value={exp.importe_deuda} divisa={exp.divisa} />
                            </p>
                            <p className="text-xs text-slate-500 mt-1">
                              Creado: {moment(exp.createdAt).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          {/* Detalle de Deuda */}
                          <div className="mt-3">
                            <p className="text-sm font-medium text-slate-700 mb-2">
                              Detalle de Deuda:
                            </p>
                            <div className="space-y-2">
                              {exp.detalle_deuda.slice(0, 2).map((detalle, idx) => (
                                <div
                                  key={idx}
                                  className="bg-slate-50 border-l-4 border-blue-400 p-2 rounded"
                                >
                                  {Object.entries(detalle).map(([key, value]) => (
                                    <p key={key} className="text-xs text-slate-600">
                                      <span className="font-medium capitalize">
                                        {key.replace("_", " ")}:
                                      </span>{" "}
                                      {String(value)}
                                    </p>
                                  ))}
                                </div>
                              ))}
                              {exp.detalle_deuda.length > 2 && (
                                <button
                                  className="text-blue-600 text-xs font-medium hover:text-blue-800 transition-colors duration-150"
                                  onClick={() => setExpandedExpedient(
                                    expandedExpedient === exp._id ? null : exp._id
                                  )}
                                >
                                  {expandedExpedient === exp._id
                                    ? "Ver menos"
                                    : `Ver ${exp.detalle_deuda.length - 2} más...`}
                                </button>
                              )}
                              {expandedExpedient === exp._id &&
                                exp.detalle_deuda.slice(2).map((detalle, idx) => (
                                  <div
                                    key={idx}
                                    className="bg-slate-50 border-l-4 border-blue-400 p-2 rounded"
                                  >
                                    {Object.entries(detalle).map(([key, value]) => (
                                      <p key={key} className="text-xs text-slate-600">
                                        <span className="font-medium capitalize">
                                          {key.replace("_", " ")}:
                                        </span>{" "}
                                        {String(value)}
                                      </p>
                                    ))}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Botones de acción */}
                  <div className="flex justify-end mt-6 space-x-3">
                    {userRole === "admin" && (
                      <button
                        onClick={handlePrepareMerge}
                        className="inline-flex items-center px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg shadow transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={selectedExpedients.length < 2}
                      >
                        <svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                        </svg>
                        Fusionar Expedientes
                      </button>
                    )}
                    <button
                      onClick={onClose}
                      className="inline-flex items-center px-4 py-2 bg-slate-100 hover:bg-slate-200 text-slate-700 rounded-lg shadow transition-colors duration-150"
                    >
                      Cerrar
                    </button>
                  </div>
                </>
              )}

              {/* Vista de resumen de fusión */}
              {showMergeSummary && (
                <div className="p-4 bg-slate-50 rounded-lg">
                  <h3 className="text-lg font-semibold text-slate-800 mb-4">
                    Selecciona el expediente principal:
                  </h3>
                  <div className="space-y-3">
                    {selectedExpedients.map((expId) => (
                      <label key={expId} className="flex items-center space-x-3 p-3 bg-white rounded-lg border border-slate-200 cursor-pointer hover:border-blue-400 transition-colors duration-150">
                        <input
                          type="radio"
                          name="mainExpedient"
                          value={expId}
                          checked={mainExpedient === expId}
                          onChange={(e) => setMainExpedient(e.target.value)}
                          className="w-4 h-4 text-blue-600 border-slate-300 focus:ring-blue-500"
                        />
                        <span className="text-sm font-medium text-slate-700">{expId}</span>
                      </label>
                    ))}
                  </div>

                  {mainExpedient && (
                    <div className="mt-6">
                      <div className="bg-white p-4 rounded-lg border border-slate-200 mb-4">
                        <p className="text-sm text-slate-600">
                          Importe total:{" "}
                          <span className="font-semibold text-slate-800">
                            <CurrencyFormatComponent 
                              value={mergeSummary?.importeTotal} 
                              divisa={debtor.expedientes.find(exp => exp._id === mainExpedient)?.divisa} 
                            />
                          </span>
                        </p>
                      </div>
                      <div className="flex justify-end space-x-3">
                        <button
                          onClick={confirmMerge}
                          disabled={loading}
                          className="inline-flex items-center px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg shadow transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {loading ? (
                            <>
                              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                              </svg>
                              Fusionando...
                            </>
                          ) : (
                            <>
                              <svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                              Confirmar Fusión
                            </>
                          )}
                        </button>
                        <button
                          onClick={onClose}
                          className="inline-flex items-center px-4 py-2 bg-slate-100 hover:bg-slate-200 text-slate-700 rounded-lg shadow transition-colors duration-150"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DebtorRow = ({ debtor, onMergeExpedients, userRole }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="bg-white rounded-xl border border-slate-200 shadow-sm hover:shadow-lg transition-all duration-200 p-5">
      <div className="space-y-3">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold text-slate-800">{debtor.nombre}</h3>
            <p className="text-sm text-slate-500 flex items-center gap-1.5">
              <svg className="w-4 h-4 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
              </svg>
              {debtor.debtornif}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium text-slate-600">
              {debtor.expedientes.length} expedientes
            </span>
          </div>
        </div>

        <div className="space-y-2">
          <p className="text-sm text-slate-600 flex items-center gap-1.5">
            <svg className="w-4 h-4 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            {debtor.email}
          </p>
          <p className="text-sm text-slate-600 flex items-center gap-1.5">
            <svg className="w-4 h-4 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            {debtor.telefono}
          </p>
        </div>
      </div>

      <div className="mt-4 pt-4 border-t border-slate-200">
        <button
          onClick={() => setIsModalOpen(true)}
          className="w-full inline-flex items-center justify-center px-4 py-2 bg-blue-50 hover:bg-blue-100 text-blue-600 font-medium rounded-lg transition-colors duration-150"
        >
          <svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </svg>
          Ver Expedientes ({debtor.expedientes.length})
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        debtor={debtor}
        onMergeExpedients={onMergeExpedients}
        userRole={userRole}
      />
    </div>
  );
};

export default DebtorRow;
