import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import ActionsOptions from "../actions/ActionsOptions";
import Pagination from "../../components/Pagination";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { EyeIcon, EyeSlashIcon, PencilIcon } from "@heroicons/react/20/solid";

// Función para aplicar estilos condicionales a Total Interacciones
const getInteractionStyle = (totalInteractions) => {
  if (totalInteractions > 15) {
    return "bg-red-100 text-red-600";
  } else if (totalInteractions >= 5) {
    return "bg-green-100 text-green-600";
  } else {
    return "bg-orange-100 text-orange-600";
  }
};

const InteractionItem = React.memo(({ interactionItem }) => (
  <div className={`mb-3 p-4 rounded-sm border ${
    interactionItem.direction === "outgoing"
      ? "bg-blue-50 border-blue-200"
      : "bg-emerald-50 border-emerald-200"
  }`}>
    <div className="flex items-start justify-between">
      <div className="flex items-center space-x-2">
        <span className={`p-2 rounded-sm ${
          interactionItem.direction === "outgoing"
            ? "bg-blue-100"
            : "bg-emerald-100"
        }`}>
          <svg className={`w-4 h-4 ${
            interactionItem.direction === "outgoing"
              ? "text-blue-600"
              : "text-emerald-600"
          }`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            {interactionItem.direction === "outgoing" 
              ? <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              : <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5l9 2-9 18-9-18 9-2zm0 0v8" />
            }
          </svg>
        </span>
        <div>
          <p className={`font-medium ${
            interactionItem.direction === "outgoing"
              ? "text-blue-800"
              : "text-emerald-800"
          }`}>
            {interactionItem.type}
          </p>
          <p className="text-sm text-slate-500">
            {moment(interactionItem.date).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
      {interactionItem.details?.message && (
        <span className={`text-xs px-2 py-1 rounded-sm ${
          interactionItem.direction === "outgoing"
            ? "bg-blue-100 text-blue-800"
            : "bg-emerald-100 text-emerald-800"
        }`}>
          Tiene mensaje
        </span>
      )}
    </div>
    {interactionItem.details?.message && (
      <div className="mt-3 pl-10">
        <p className="text-sm text-slate-600">{interactionItem.details.message}</p>
      </div>
    )}
  </div>
));

const AnnotationItem = React.memo(({ annotation }) => (
  <div className="mb-3 p-4 rounded-lg bg-gray-50 border border-gray-200">
    <div className="flex items-start space-x-3">
      <div className="p-2 rounded-full bg-gray-100">
        ✏️
      </div>
      <div>
        <p className="font-medium text-gray-800">{annotation.anotacion}</p>
        <p className="text-sm text-gray-500">
          {moment(annotation.fecha).format("DD/MM/YYYY")}
        </p>
      </div>
    </div>
  </div>
));

const DetailSection = React.memo(({ title, children, icon }) => (
  <div className="mb-6">
    <div className="flex items-center space-x-2 mb-4">
      <span className="text-xl">{icon}</span>
      <h3 className="text-lg font-semibold text-slate-800">{title}</h3>
    </div>
    {children}
  </div>
));

const Interaction = ({ interaction, toggleIsDone }) => {
  const [showDetails, setShowDetails] = useState(false); 
  const [visibleAnnotations, setVisibleAnnotations] = useState(10);
  const [visibleInteractions, setVisibleInteractions] = useState(10);

  const formattedDate = interaction.createdAt
    ? moment(interaction.createdAt).format("DD/MM/YYYY")
    : "No Date";
  const importeDeudaFormatted = interaction.importe_deuda / 1;

  const loadMoreAnnotations = () => {
    setVisibleAnnotations((prev) => prev + 10);
  };

  const loadMoreInteractions = () => {
    setVisibleInteractions((prev) => prev + 10);
  };

  return (
    <>
      <tr className="hover:bg-gray-50 transition-colors duration-150">
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">{formattedDate}</div>
        </td>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">{interaction.nombre_comercial}</div>
        </td>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">
            {interaction.deudores && interaction.deudores.length > 0
              ? interaction.deudores[0].debtorname
              : "Sin deudor"}
          </div>
        </td>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">
            <CurrencyFormat
              value={importeDeudaFormatted}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix={" €"}
              decimalScale={2}
            />
          </div>
        </td>
        <td className="px-3 py-2 w-[70px] whitespace-nowrap text-center">
          <div
            className={`text-center font-semibold px-2 py-1 rounded ${getInteractionStyle(
              interaction.total_interactions
            )}`}
          >
            {interaction.total_interactions}
            {interaction.total_interactions > 15 && " 🔥"}
          </div>
        </td>
        <td className="px-3 py-2 whitespace-nowrap text-center">
          <label className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={interaction.isDone === true}
              onChange={() => toggleIsDone(interaction.expedient_id, interaction.isDone)}
              className="hidden"
            />
            <span
              className={`w-10 h-5 flex items-center flex-shrink-0 p-1 rounded-full transition-colors duration-150 ${
                interaction.isDone ? "bg-green-400" : "bg-gray-300"
              }`}
            >
              <span
                className={`w-4 h-4 bg-white rounded-full shadow transform transition-transform duration-150 ${
                  interaction.isDone ? "translate-x-5" : "translate-x-0"
                }`}
              ></span>
            </span>
          </label>
        </td>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="flex space-x-2 items-center">
            <button
              className="text-blue-500 hover:text-blue-600 transition-colors duration-150"
              onClick={() => window.open(`/view/${interaction.expedient_id}`, '_blank')}
            >
              <PencilIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
            </button>

            <button
              className="text-blue-500 hover:text-blue-600 transition-colors duration-150"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? (
                <EyeSlashIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              ) : (
                <EyeIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              )}
            </button>
          </div>
        </td>
      </tr>

      {showDetails && (
        <tr>
          <td colSpan="7" className="bg-white">
            <div className="p-6 border-t border-gray-100">
              {/* Detalles de la deuda */}
              <DetailSection title="Detalle deuda" icon="💰">
                <div className="bg-white rounded-lg border border-gray-200 p-4">
                  {interaction.detalle_deuda && interaction.detalle_deuda.length > 0 ? (
                    <div className="space-y-2">
                      {interaction.detalle_deuda.map((detalle, index) => (
                        <div key={index} className="flex justify-between items-center p-2 hover:bg-gray-50 rounded-lg">
                          <div className="flex items-center space-x-3">
                            <span className="text-gray-500 text-sm">
                              {detalle.numero_factura || "Sin número de factura"}
                            </span>
                          </div>
                          <CurrencyFormat
                            value={detalle.importe}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={" €"}
                            decimalScale={2}
                            renderText={(value) => (
                              <span className="font-medium text-gray-900">{value}</span>
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500 text-center">
                      No hay detalles de deuda disponibles.
                    </p>
                  )}
                </div>
                <div className="mt-4">
                  <ActionsOptions
                    expedientId={interaction.expedient_id}
                    debtorId={
                      interaction.deudores && interaction.deudores.length > 0
                        ? interaction.deudores[0].debtorid
                        : null
                    }
                  />
                </div>
              </DetailSection>

              {/* Anotaciones */}
              <DetailSection title="Anotaciones" icon="📌">
                {interaction.annotations.length > 0 ? (
                  <div className="space-y-2">
                    {interaction.annotations
                      .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
                      .slice(0, visibleAnnotations)
                      .map((annotation) => (
                        <AnnotationItem key={annotation._id} annotation={annotation} />
                      ))}
                    {visibleAnnotations < interaction.annotations.length && (
                      <button
                        onClick={loadMoreAnnotations}
                        className="mt-4 text-sm text-blue-600 hover:text-blue-700 font-medium"
                      >
                        Ver más anotaciones...
                      </button>
                    )}
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">No hay anotaciones.</p>
                )}
              </DetailSection>

              {/* Interacciones */}
              <DetailSection title="Interacciones" icon="💬">
                {interaction.interactions.length > 0 ? (
                  <div className="space-y-2">
                    {interaction.interactions
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .slice(0, visibleInteractions)
                      .map((interactionItem) => (
                        <InteractionItem
                          key={interactionItem.interaction_id}
                          interactionItem={interactionItem}
                        />
                      ))}
                    {visibleInteractions < interaction.interactions.length && (
                      <button
                        onClick={loadMoreInteractions}
                        className="mt-4 text-sm text-blue-600 hover:text-blue-700 font-medium"
                      >
                        Ver más interacciones...
                      </button>
                    )}
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">No hay interacciones.</p>
                )}
              </DetailSection>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const CompletedInteractionsTable = React.memo(({ interactions, toggleIsDone, currentPage, totalPages, onPageChange }) => {
  return (
    <>
      <table className="table-auto w-full text-left">
        <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
          <tr>
            <th className="px-3 py-2">Fecha</th>
            <th className="px-3 py-2">Cliente</th>
            <th className="px-3 py-2">Deudor</th>
            <th className="px-3 py-2">Importe Deuda</th>
            <th className="px-3 py-2">Interacciones</th>
            <th className="px-3 py-2">Estado</th>
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-200">
          {interactions.map((interaction) => (
            <tr key={interaction.expedient_id}>
              <td className="px-3 py-2">
                {moment(interaction.createdAt).format("DD/MM/YYYY")}
              </td>
              <td className="px-3 py-2">{interaction.nombre_comercial}</td>
              <td className="px-3 py-2">
                {interaction.deudores?.[0]?.debtorname || "Sin deudor"}
              </td>
              <td className="px-3 py-2">
                <CurrencyFormat
                  value={interaction.importe_deuda}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  suffix={" €"}
                  decimalScale={2}
                />
              </td>
              <td className="px-3 py-2 text-center">
                {interaction.total_interactions || 0}
              </td>
              <td className="px-3 py-2 text-center">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={interaction.isDone === true}
                    onChange={() =>
                      toggleIsDone(
                        interaction.expedient_id,
                        interaction.isDone
                      )
                    }
                    className="hidden"
                  />
                  <span
                    className={`w-10 h-5 flex items-center flex-shrink-0 p-1 rounded-full ${
                      interaction.isDone
                        ? "bg-green-400"
                        : "bg-gray-300"
                    }`}
                  >
                    <span
                      className={`w-4 h-4 bg-white rounded-full shadow transform transition-transform ${
                        interaction.isDone
                          ? "translate-x-5"
                          : "translate-x-0"
                      }`}
                    ></span>
                  </span>
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div className="mt-4">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </>
  );
});

const CompletedInteractionsModal = ({ isOpen, onClose, interactions, toggleIsDone, currentPage, totalPages, onPageChange }) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-6">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-lg bg-white shadow-xl transition-all">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-gray-900">
                    ✅ Interacciones Completadas
                  </h3>
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    ✖
                  </button>
                </div>
                <div className="mt-4">
                  {interactions.length > 0 ? (
                    <CompletedInteractionsTable
                      interactions={interactions}
                      toggleIsDone={toggleIsDone}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={onPageChange}
                    />
                  ) : (
                    <p className="text-gray-500">No hay interacciones completadas.</p>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

const InteractionsListComponent = () => {
  // Estados para interacciones pendientes
  const [interactions, setInteractions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  // Estados para interacciones completadas
  const [completedInteractions, setCompletedInteractions] = useState([]);
  const [completedCurrentPage, setCompletedCurrentPage] = useState(1);
  const [completedTotalPages, setCompletedTotalPages] = useState(1);

  // Estados de UI
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const itemsPerPage = 10;

  // Efecto para cargar interacciones pendientes
  useEffect(() => {
    const fetchInteractions = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseAPI}/expedients/unread-interactions?page=${currentPage}&limit=${itemsPerPage}`
        );
        setInteractions(response.data.expedients);
        setTotalPages(response.data.totalPages);
        setTotalUnreadCount(response.data.totalUnreadCount);
      } catch (error) {
        console.error("Error fetching interactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInteractions();
  }, [currentPage]);

  // Efecto para cargar interacciones completadas
  useEffect(() => {
    const fetchCompletedInteractions = async () => {
      if (!modalOpen) return;
      
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseAPI}/expedients/done-interactions?page=${completedCurrentPage}&limit=${itemsPerPage}`
        );
        setCompletedInteractions(response.data.expedients);
        setCompletedTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching completed interactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompletedInteractions();
  }, [modalOpen, completedCurrentPage]);

  const toggleIsDone = async (expedientId, currentState) => {
    try {
      await axios.post(`${baseAPI}/expedients/update-isdone`, {
        expedient_id: expedientId,
        isDone: !currentState,
      });
      window.location.reload();
    } catch (error) {
      console.error("Error updating isDone", error);
    }
  };

  return (
    <div className="p-3">
      {/* Header Actions */}
      <div className="sm:flex sm:justify-between sm:items-center mb-5">
        <div className="mb-4 sm:mb-0">
          <div className="text-sm text-slate-500">
            Pendientes: <span className="font-semibold text-slate-800">{totalUnreadCount}</span>
          </div>
        </div>
        <div>
          <button
            onClick={() => setModalOpen(true)}
            className="btn bg-blue-500 hover:bg-blue-600 text-white"
          >
            <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
            <span className="hidden xs:block ml-2">Ver Completadas</span>
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="bg-white">
        {loading ? (
          <div className="text-center py-10">
            <div className="inline-flex items-center justify-center">
              <svg className="animate-spin w-6 h-6 fill-current text-slate-400" viewBox="0 0 24 24">
                <path d="M12 22c5.523 0 10-4.477 10-10h-2c0 4.418-3.582 8-8 8s-8-3.582-8-8c0-4.418 3.582-8 8-8V2C6.477 2 2 6.477 2 12c0 5.523 4.477 10 10 10z" />
              </svg>
              <span className="ml-2">Cargando...</span>
            </div>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="table-auto w-full divide-y divide-slate-200">
              <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50">
                <tr>
                  <th className="px-3 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Fecha Creación</div>
                  </th>
                  <th className="px-3 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Cliente</div>
                  </th>
                  <th className="px-3 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Deudor</div>
                  </th>
                  <th className="px-3 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Importe Deuda</div>
                  </th>
                  <th className="px-3 py-2 whitespace-nowrap">
                    <div className="font-semibold text-center">Interacciones</div>
                  </th>
                  <th className="px-3 py-2 whitespace-nowrap">
                    <div className="font-semibold text-center">Estado</div>
                  </th>
                  <th className="px-3 py-2 whitespace-nowrap">
                    <div className="font-semibold text-center">Acciones</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-slate-200">
                {interactions.map((interaction) => (
                  <Interaction
                    key={interaction.expedient_id}
                    interaction={interaction}
                    toggleIsDone={toggleIsDone}
                  />
                ))}
              </tbody>
            </table>

            {interactions.length === 0 && (
              <div className="text-center py-10">
                <div className="inline-flex items-center justify-center">
                  <svg className="w-12 h-12 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                  </svg>
                </div>
                <h3 className="mt-2 font-medium text-slate-800">No hay interacciones</h3>
                <p className="mt-1 text-sm text-slate-500">No hay interacciones registradas en este momento.</p>
              </div>
            )}
          </div>
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="mt-8">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>

      {/* Modal */}
      <CompletedInteractionsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        interactions={completedInteractions}
        toggleIsDone={toggleIsDone}
        currentPage={completedCurrentPage}
        totalPages={completedTotalPages}
        onPageChange={setCompletedCurrentPage}
      />
    </div>
  );
};

export default InteractionsListComponent; 