import React, { useState } from "react";
import { read, utils } from "xlsx";
import axios from "axios";
import { baseAPI } from "../config";
import { toast } from "react-toastify";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import { 
  DocumentArrowUpIcon, 
  TableCellsIcon, 
  ArrowUpTrayIcon,
  DocumentCheckIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline';

const ImportExpedients = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [expedients, setExpedients] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    processFile(file);
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    processFile(file);
  };

  const processFile = (file) => {
    if (!file || !file.name.match(/\.(xlsx|xls)$/)) {
      toast.error("Por favor, selecciona un archivo Excel válido (.xlsx, .xls)", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      try {
        const bufferArray = e.target.result;
        const wb = read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = utils.sheet_to_json(ws);
        setExpedients(data);
        
        toast.success("Archivo procesado correctamente", {
          position: "top-right",
          autoClose: 2000,
        });
      } catch (error) {
        toast.error("Error al procesar el archivo", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    };
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const createExpedients = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      const servicios = [
        { tipo: "burofax", fecha: new Date(), estado: "pendiente", costo: 0, detalles: "Envío de burofax certificado al deudor", cantidad: 999, utilizados: 0 },
        { tipo: "llamada_robotizada", fecha: new Date(), estado: "pendiente", costo: 0, detalles: "Envío de llamada robotizada", cantidad: 999, utilizados: 0 },
        { tipo: "llamada_personal", fecha: new Date(), estado: "pendiente", costo: 0, detalles: "Envío de llamada personal", cantidad: 999, utilizados: 0 },
        { tipo: "sms", fecha: new Date(), estado: "pendiente", costo: 0, detalles: "Envío de sms", cantidad: 999, utilizados: 0 },
        { tipo: "email", fecha: new Date(), estado: "pendiente", costo: 0, detalles: "Envío de email", cantidad: 999, utilizados: 0 },
      ];

      for (const expedient of expedients) {
        expedient.servicios = servicios;
        const response = await axios.post(`${baseAPI}/expedients/add`, expedient);
        
        if (response.status === 200) {
          if (response.data.type === "create") {
            toast.success("Expediente Creado! 😄", { autoClose: 1000 });
          } else if (response.data.type === "update") {
            toast.info("Expediente Actualizado! 🔄", { autoClose: 1000 });
          }
        }
      }

      toast.success("¡Todos los expedientes procesados correctamente! 🎉", {
        position: "top-right",
        autoClose: 3000,
      });

      setTimeout(() => {
        window.location = "/expedients";
      }, 2000);
    } catch (error) {
      console.error(error);
      toast.error("Error al procesar los expedientes", {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-slate-50">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <div className="mb-8">
            <h1 className="text-2xl md:text-3xl font-bold text-slate-800">
              Importación de Expedientes
            </h1>
            <p className="mt-2 text-sm text-slate-600">
              Importa tus expedientes desde un archivo Excel de forma rápida y segura
            </p>
          </div>

          <div className="bg-white shadow-sm rounded-xl border border-slate-200 overflow-hidden">
            <div className="p-6">
              {expedients.length === 0 ? (
                <div className="max-w-2xl mx-auto text-center">
                  {/* Badges de información */}
                  <div className="flex justify-center space-x-6 mb-8">
                    <div className="text-center">
                      <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-3">
                        <DocumentArrowUpIcon className="w-6 h-6 text-blue-600" />
                      </div>
                      <p className="text-sm font-medium text-slate-600">Formato Excel</p>
                    </div>
                    <div className="text-center">
                      <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-3">
                        <TableCellsIcon className="w-6 h-6 text-blue-600" />
                      </div>
                      <p className="text-sm font-medium text-slate-600">Datos Estructurados</p>
                    </div>
                    <div className="text-center">
                      <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mb-3">
                        <DocumentCheckIcon className="w-6 h-6 text-blue-600" />
                      </div>
                      <p className="text-sm font-medium text-slate-600">Validación Automática</p>
                    </div>
                  </div>

                  {/* Plantilla descargable */}
                  <div className="mb-8">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1JLB5RD2X6vbbukuUY4h9f3ohf8AplU--/edit?usp=share_link&ouid=110409759592739368353&rtpof=true&sd=true"
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex items-center px-4 py-2 bg-white border border-slate-200 rounded-lg text-sm font-medium text-slate-600 hover:bg-slate-50 transition-colors duration-150"
                    >
                      <ArrowDownTrayIcon className="w-5 h-5 mr-2 text-slate-400" />
                      Descargar plantilla
                    </a>
                  </div>

                  {/* Área de drop */}
                  <div
                    className={`relative rounded-xl border-2 border-dashed transition-all duration-150 ${
                      isDragging
                        ? "border-blue-500 bg-blue-50"
                        : "border-slate-200 hover:border-slate-300 bg-slate-50"
                    }`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                  >
                    <input
                      type="file"
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      onChange={handleFileInput}
                      accept=".xlsx,.xls"
                    />
                    <div className="p-8">
                      <div className="mb-4">
                        <ArrowUpTrayIcon className="mx-auto h-12 w-12 text-slate-400" />
                      </div>
                      <p className="text-sm text-slate-600 mb-1">
                        Arrastra tu archivo Excel aquí o
                        <span className="text-blue-600 font-medium"> haz clic para seleccionar</span>
                      </p>
                      <p className="text-xs text-slate-500">
                        Formatos soportados: .xlsx, .xls
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/* Resumen de importación */}
                  <div className="mb-6 p-4 bg-blue-50 rounded-lg border border-blue-100">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <DocumentCheckIcon className="w-5 h-5 text-blue-600 mr-2" />
                        <span className="text-sm font-medium text-slate-700">
                          {expedients.length} expedientes listos para importar
                        </span>
                      </div>
                      <button
                        className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-150 ${
                          isProcessing ? "opacity-75 cursor-not-allowed" : ""
                        }`}
                        onClick={createExpedients}
                        disabled={isProcessing}
                      >
                        {isProcessing ? (
                          <>
                            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                            Procesando...
                          </>
                        ) : (
                          <>
                            <ArrowUpTrayIcon className="w-4 h-4 mr-2" />
                            Importar expedientes
                          </>
                        )}
                      </button>
                    </div>
                  </div>

                  {/* Tabla de previsualización */}
                  <div className="rounded-lg border border-slate-200 overflow-hidden">
                    <div className="overflow-x-auto">
                      <table className="table-auto w-full">
                        <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-b border-slate-200">
                          <tr>
                            <th className="px-3 py-4 whitespace-nowrap">
                              <div className="font-semibold text-left">ID Key</div>
                            </th>
                            <th className="px-3 py-4 whitespace-nowrap">
                              <div className="font-semibold text-left">User ID</div>
                            </th>
                            <th className="px-3 py-4 whitespace-nowrap">
                              <div className="font-semibold text-left">Deudor</div>
                            </th>
                            <th className="px-3 py-4 whitespace-nowrap">
                              <div className="font-semibold text-left">Importe</div>
                            </th>
                            <th className="px-3 py-4 whitespace-nowrap">
                              <div className="font-semibold text-left">Tipo</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-slate-200">
                          {expedients.map((expedient) => (
                            <tr key={expedient.id_key}>
                              <td className="px-3 py-3 whitespace-nowrap">
                                <div className="font-medium text-slate-800">{expedient.id_key}</div>
                              </td>
                              <td className="px-3 py-3 whitespace-nowrap">
                                <div className="text-slate-800">{expedient.user_id}</div>
                              </td>
                              <td className="px-3 py-3 whitespace-nowrap">
                                <div className="text-slate-800">{expedient.deudor}</div>
                              </td>
                              <td className="px-3 py-3 whitespace-nowrap">
                                <div className="font-medium text-emerald-600">{expedient.importe_deuda}€</div>
                              </td>
                              <td className="px-3 py-3 whitespace-nowrap">
                                <div className="text-slate-800">{expedient.tipo_reclamacion}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ImportExpedients;
