import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from "../images/claim-up-19.png";

function Footer() {
  return (
    <footer className="bg-white border-t border-slate-200 mt-auto">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="py-8 md:py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Logo and Description */}
            <div className="flex flex-col">
              <div className="mb-4">
                <Link to="/" className="inline-block" aria-label="Claim Up">
                  <img 
                    src={LogoImage} 
                    className="h-20 w-auto" 
                    alt="Claim Up software de reclamación de deudas" 
                  />
                </Link>
              </div>
              <div className="text-slate-500 text-sm">
                Software inteligente para la gestión y recuperación de deudas
              </div>
            </div>

            {/* Contact Info */}
            <div className="flex flex-col md:items-end">
              <h6 className="text-slate-800 font-medium mb-4">Contacto</h6>
              <ul className="space-y-3">
                <li>
                  <a 
                    className="text-slate-500 hover:text-slate-700 transition duration-150 ease-in-out flex items-center gap-2" 
                    href='mailto:legal@claimup.io'
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span>legal@claimup.io</span>
                  </a>
                </li>
                <li>
                  <a 
                    className="text-slate-500 hover:text-slate-700 transition duration-150 ease-in-out flex items-center gap-2" 
                    href='tel:+34626728143'
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                    </svg>
                    <span>+34 626728143</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Bottom area */}
        <div className="py-6 border-t border-slate-200">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
            {/* Copyrights note */}
            <div className="text-sm text-slate-500">
              &copy; www.claimup.io / Todos los derechos reservados.
            </div>

            {/* Social links */}
            <div className="flex justify-start md:justify-end">
              <a 
                href="https://www.linkedin.com/company/claim-up/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex justify-center items-center text-slate-500 hover:text-slate-700 bg-white hover:bg-slate-100 rounded-full shadow transition duration-150 ease-in-out p-2" 
                aria-label="LinkedIn"
              >
                <svg className="w-5 h-5 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
