import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../config";
import CurrencyFormat from "react-currency-format";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ActionMenu from "../partials/actions/ActionsMenu";
import { EyeIcon, EyeSlashIcon, PencilIcon } from "@heroicons/react/20/solid";
import moment from "moment";

const FocusExpedients = ({ expedient }) => {
  const [showAnnotations, setShowAnnotations] = useState(false); // Estado para controlar la visibilidad de las anotaciones
  const importeDeudaFormatted = expedient.importe_deuda / 1;

  return (
    <>
      <tr>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">{expedient.expedient_id}</div>
        </td>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">
            <CurrencyFormat
              value={importeDeudaFormatted}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix={" €"}
              decimalScale={2}
            />
          </div>
        </td>

        {/* Estado Focus con nombre entendible */}
        <td className="px-3 py-2 whitespace-nowrap text-center">
          <div className="text-left text-gray-800">
            {expedient.statusFocus}
          </div>
        </td>

        <td className="px-3 py-2 w-[70px] whitespace-nowrap text-center">
          <div className={`text-center font-semibold px-2 py-1 rounded`}>
            {expedient.estado}
          </div>
        </td>

        <td className="px-3 py-2 whitespace-nowrap">
          <div className="flex space-x-2 items-center">
            {/* Botón para ver el expediente */}
            <button
              className="text-indigo-500 hover:text-indigo-600"
              onClick={() =>
                (window.location.href = `/view/${expedient.expedient_id}`)
              }
            >
              <PencilIcon
                className="h-4 w-4 sm:h-5 sm:w-5"
                aria-hidden="true"
              />
            </button>

            {/* Botón para mostrar/ocultar anotaciones */}
            <button
              className="text-indigo-500 hover:text-indigo-600"
              onClick={() => setShowAnnotations(!showAnnotations)}
            >
              {showAnnotations ? (
                <EyeSlashIcon
                  className="h-4 w-4 sm:h-5 sm:w-5"
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              )}
            </button>

            {/* ActionMenu para enviar email */}
            <ActionMenu expedientId={expedient.expedient_id} />
          </div>
        </td>
      </tr>

      {/* Renderizar las anotaciones si showAnnotations está activo */}
      {showAnnotations && (
        <tr>
          <td colSpan="5" className="bg-gray-50">
            <div className="p-4 border-t border-gray-200 rounded-b-lg">
              <h3 className="font-semibold text-gray-800 mb-4">
                📌 Anotaciones
              </h3>
              {expedient.annotations.length > 0 ? (
                <div className="space-y-3">
                  {expedient.annotations.map((annotation) => (
                    <div
                      key={annotation._id}
                      className="bg-white p-3 shadow-sm rounded-lg border border-gray-200"
                    >
                      <div className="text-sm">
                        <div className="font-semibold text-gray-700 mb-1">
                          ✏️ <strong>Anotación:</strong> {annotation.anotacion}
                        </div>
                        <div className="text-gray-500">
                          📅 <strong>Fecha:</strong>{" "}
                          {moment(annotation.fecha).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No hay anotaciones.</p>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const FocusExpedientsList = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [expedients, setExpedients] = useState([]); // Estado para guardar los expedientes
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [sortField, setSortField] = useState("importe_deuda"); // Campo para ordenar
  const [sortOrder, setSortOrder] = useState("asc"); // Orden asc o desc
  const [filterEstado, setFilterEstado] = useState("All"); // Estado del filtro

  const userToken = localStorage.getItem("token");

  if (userToken == null) {
    window.location = "/login";
  }

  // Llamada a la API para obtener los expedientes focus
  useEffect(() => {
    const fetchExpedients = async () => {
      try {
        const response = await axios.get(`${baseAPI}/expedients/focus`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        setExpedients(response.data); // Guardar los datos de la API en el estado
      } catch (error) {
        console.error("Error fetching expedients", error);
      } finally {
        setLoading(false); // Dejar de mostrar el loader
      }
    };

    fetchExpedients();
  }, [userToken]);

  // Filtrar expedientes por estado
  const filteredExpedients = expedients.filter(
    (expedient) =>
      filterEstado === "All" || expedient.statusFocus === filterEstado
  );

  // Función para ordenar los expedientes
  const sortExpedients = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc"; // Cambia el orden si ya está ordenado por el mismo campo
    setSortField(field);
    setSortOrder(order);

    const sortedExpedients = [...filteredExpedients].sort((a, b) => {
      let comparison = 0;
      if (field === "importe_deuda") {
        comparison = a.importe_deuda - b.importe_deuda;
      }
      return order === "asc" ? comparison : -comparison;
    });

    setExpedients(sortedExpedients);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/* Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-6 w-full max-w-9xl mx-auto">

            {/* Filtro por estado */}
            <div className="mb-4">
              <label htmlFor="estado" className="block text-sm font-medium text-gray-700">
                Filtrar por estado
              </label>
              <select
                id="estado"
                name="estado"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={filterEstado}
                onChange={(e) => setFilterEstado(e.target.value)}
              >
                <option value="All">Todos</option>
                <option value="noAnnotationslast30DaysAndDebtGreaterThan100">
                  No Annotations last 30 days and Debt greater than 100
                </option>
                <option value="emailOpenedMoreThan3TimesLast10DaysAndDebtGreaterThan100">
                  Email opened more than 3 times last 10 days and Debt greater than 100
                </option>
                {/* Agregar más opciones según los estados */}
              </select>
            </div>

            {/* Tabla de expedientes */}
            <div className="grid grid-cols-12 gap-4 mt-4">
              {loading ? (
                <p className="text-center col-span-12">Cargando...</p>
              ) : (
                <div className="col-span-12">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full text-left">
                      <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
                        <tr>
                          <th className="px-3 py-2">Expedient ID</th>
                          <th
                            className="px-3 py-2 cursor-pointer"
                            onClick={() => sortExpedients("importe_deuda")}
                          >
                            <div className="flex items-center">
                              Importe Deuda{" "}
                              {sortField === "importe_deuda" &&
                                (sortOrder === "asc" ? "🔼" : "🔽")}
                            </div>
                          </th>
                          <th className="px-3 py-2">Estado Focus</th>
                          <th className="px-3 py-2">Estado</th>
                          <th className="px-3 py-2">Acciones</th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-200">
                        {filteredExpedients.map((expedient, index) => (
                          <FocusExpedients
                            key={`${expedient.expedient_id}_${index}`} // Clave única combinada con el índice
                            expedient={expedient}
                          />
                        ))}
                      </tbody>
                    </table>
                    {filteredExpedients.length === 0 && (
                      <div className="text-center text-gray-400 py-6">
                        No hay expedientes focus.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default FocusExpedientsList;
