import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";

function UpdateExpedient(props) {
  const [importe_deuda, setImporteDeuda] = useState(0);
  const [tipo_deuda, setTipoDeuda] = useState("");
  const [presupuesto, setPresupuesto] = useState(0);
  const [porcentajeBudget, setPorcentajeBudget] = useState(0);
  const [porcentajeDescuento, setPorcentajeDescuento] = useState(0);
  const [estado, setEstado] = useState("");
  const [expedientId] = useState(props.idExpedient);
  const [aditionalExpedientData, setAditionalExpedientData] = useState("");
  const [clientInternalReference, setClientInternalReference] = useState("");
  const [divisa, setDivisa] = useState("EUR");

  useEffect(() => {
    axios
      .get(`${baseAPI}/expedients/` + expedientId)
      .then((response) => {
        setImporteDeuda(response.data.importe_deuda);
        setTipoDeuda(response.data.tipo_deuda);
        setEstado(response.data.estado);
        setPresupuesto(response.data.presupuesto);
        setPorcentajeBudget(response.data.porcentaje_presupuesto);
        setPorcentajeDescuento(response.data.porcentaje_descuento_deuda);
        setClientInternalReference(response.data.client_internal_reference);
        setAditionalExpedientData(response.data.aditional_expedient_data);
        setDivisa(response.data.divisa || "EUR");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [expedientId]);

  const onChangeImporteDeuda = (e) => {
    setImporteDeuda(e.target.value.replace(",", "."));
  };

  const onChangeTipoDeuda = (e) => {
    setTipoDeuda(e.target.value);
  };

  const onChangeEstado = (e) => {
    setEstado(e.target.value);
  };

  const onChangePresupuesto = (e) => {
    setPresupuesto(e.target.value.replace(",", "."));
  };

  const onChangePorcentajeBudget = (e) => {
    setPorcentajeBudget(e.target.value.replace(",", "."));
  };

  const onChangePorcentajeDescuento = (e) => {
    setPorcentajeDescuento(e.target.value.replace(",", "."));
  };

  const onChangeClientInternalReference = (e) => {
    setClientInternalReference(e.target.value);
  };

  const onChangeAditionalExpedientData = (e) => {
    setAditionalExpedientData(e.target.value);
  };

  const onChangeDivisa = (e) => {
    setDivisa(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const expedient = {
      importe_deuda: importe_deuda,
      tipo_deuda: tipo_deuda,
      presupuesto: presupuesto,
      porcentaje_presupuesto: porcentajeBudget,
      estado: estado,
      porcentaje_descuento_deuda: porcentajeDescuento,
      client_internal_reference: clientInternalReference,
      aditional_expedient_data: aditionalExpedientData,
      divisa: divisa,
    };
    console.log(expedient);
    console.log(baseAPI);
    async function updateExpedientId(expedient) {
      const response = await axios.post(
        `${baseAPI}/expedients/update/${expedientId}`,
        expedient
      );
      return response;
    }
    updateExpedientId(expedient).then((response) => {
      if (response.status === 200) {
        toast.success("Expediente actualizado! 😄 ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  return (
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Actualizar Expediente</h2>
      </header>
      
      <div className="p-5">
        <form onSubmit={onSubmit} className="space-y-6">
          <div className="bg-gray-50 border border-gray-200 rounded-sm p-6">
            <h2 className="text-lg font-semibold text-gray-800 pb-3 border-b border-gray-200 mb-6">
              Datos del Expediente
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="importe-deuda"
                >
                  Importe de la Deuda
                </label>
                <div className="flex space-x-2">
                  <input
                    className="flex-1 px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                    id="importe-deuda"
                    type="number"
                    placeholder="Deuda"
                    onChange={onChangeImporteDeuda}
                    required={true}
                    value={importe_deuda}
                  />
                  <select
                    className="w-24 px-2 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                    value={divisa}
                    onChange={onChangeDivisa}
                    required={true}
                  >
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                    <option value="GBP">GBP</option>
                    <option value="JPY">JPY</option>
                    <option value="CHF">CHF</option>
                    <option value="AUD">AUD</option>
                    <option value="CAD">CAD</option>
                    <option value="CNY">CNY</option>
                  </select>
                </div>
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="tipo-deuda"
                >
                  Tipo de deuda
                </label>
                <select
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  id="tipo-deuda"
                  onChange={onChangeTipoDeuda}
                  required={true}
                  value={tipo_deuda}
                >
                  <option>-Selecciona una opción-</option>
                  <option>Factura impagada</option>
                  <option>Reconocimiento de deuda</option>
                  <option>Préstamo personal sin garantía</option>
                  <option>Préstamo hipotecario</option>
                  <option>Pagaré</option>
                  <option>Tarjeta de crédito</option>
                  <option>Tarjeta revolving</option>
                  <option>Impago suscripción recurrente</option>
                  <option>Contrato de arrendamiento</option>
                  <option>Otro tipo de deuda</option>
                </select>
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="presupuesto"
                >
                  Presupuesto fijo
                </label>
                <input
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  id="presupuesto"
                  type="number"
                  onChange={onChangePresupuesto}
                  value={presupuesto}
                />
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="porcentaje-exito"
                >
                  % de éxito
                </label>
                <input
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  id="porcentaje-exito"
                  type="number"
                  placeholder="% de éxito"
                  onChange={onChangePorcentajeBudget}
                  value={porcentajeBudget}
                />
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="referencia-cliente"
                >
                  Referencia cliente
                </label>
                <input
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  id="referencia-cliente"
                  type="text"
                  placeholder="Referencia cliente"
                  onChange={onChangeClientInternalReference}
                  value={clientInternalReference}
                />
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="info-adicional"
                >
                  Información adicional
                </label>
                <input
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  id="info-adicional"
                  type="text"
                  placeholder="Información adicional"
                  onChange={onChangeAditionalExpedientData}
                  value={aditionalExpedientData}
                />
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="estado"
                >
                  Estado
                </label>
                <select
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  id="estado"
                  onChange={onChangeEstado}
                  required={true}
                  value={estado}
                >
                  <option>On going</option>
                  <option>Email opened</option>
                  <option>Hot</option>
                  <option>Cold</option>
                  <option>Fallido</option>
                  <option>Ilocalizable</option>
                  <option>Sin datos de contacto</option>
                  <option>Cancelled</option>
                  <option>Dispute</option>
                  <option>Burofax Enviado</option>
                  <option>Burofax con resultado negativo</option>
                  <option>Burofax con resultado positivo</option>
                  <option>Demanda pendiente</option>
                  <option>Demanda preparada</option>
                  <option>Demanda presentada</option>
                  <option>Demanda Admitida</option>
                  <option>Demanda No Admitida</option>
                  <option>Oposición del Deudor</option>
                  <option>Pendiente Requerimiento</option>
                  <option>Requerimiento Judicial Positivo</option>
                  <option>Pendiente Ejecucion</option>
                  <option>Sentencia Estimatoria</option>
                  <option>Sentencia Desestimatoria</option>
                  <option>Sentencia Parcial</option>
                  <option>Cobrado</option>
                  <option>Cobrado Judicialmente</option>
                </select>
              </div>

              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-2"
                  htmlFor="porcentaje-descuento"
                >
                  % de descuento deuda
                </label>
                <input
                  className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 bg-white"
                  id="porcentaje-descuento"
                  type="number"
                  placeholder="% de descuento"
                  onChange={onChangePorcentajeDescuento}
                  value={porcentajeDescuento}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              type="submit"
            >
              Actualizar Expediente
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateExpedient;
