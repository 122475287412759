import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { 
  UserIcon, 
  BuildingOfficeIcon, 
  DocumentTextIcon,
  CheckCircleIcon,
  EnvelopeIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline';

const initialUserData = {
  username: "",
  userlastname: "",
  usermail: "",
  userphone: "",
  invoicedataname: "",
  invoicedataaddress: "",
  invoicedatacity: "",
  invoicedatacountry: "",
  invoicedatazip: "",
  invoicedatacif: "",
  invoicedataemail: "",
  judicialPercentage: "",
  extrajudicialPercentage: "",
  emailTemplates: {
    default: [],
    customFields: []
  }
};

const AccountPanel = ({ userId }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(initialUserData);
  const [userRole, setUserRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [newCustomField, setNewCustomField] = useState({ fieldName: "", templates: [""] });

  const getToken = () => {
    try {
      return localStorage.getItem("token");
    } catch (error) {
      console.error("Error accessing localStorage:", error);
      return null;
    }
  };

  const setToken = (token) => {
    try {
      localStorage.setItem("token", token);
      return true;
    } catch (error) {
      console.error("Error setting token in localStorage:", error);
      return false;
    }
  };

  useEffect(() => {
    if (!userId) {
      toast.error("ID de usuario no válido", {
        position: "top-right",
        autoClose: 3000,
      });
      navigate("/dashboard");
      return;
    }

    const token = getToken();
    if (!token) {
      toast.error("Sesión no válida", {
        position: "top-right",
        autoClose: 3000,
      });
      navigate("/login");
      return;
    }

    try {
      const decoded = jwtDecode(token);
      setUserRole(decoded.role);

      const headers = {
        Authorization: `Bearer ${token}`
      };

      axios
        .get(`${baseAPI}/users/${userId}`, { headers })
        .then((response) => {
          const responseData = response.data || {};
          setUserData({
            ...initialUserData,
            ...responseData,
            emailTemplates: {
              default: responseData.emailTemplates?.default || [],
              customFields: responseData.emailTemplates?.customFields || []
            }
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          toast.error("Error al cargar los datos del usuario", {
            position: "top-right",
            autoClose: 3000,
          });
          setIsLoading(false);
          if (error.response?.status === 401) {
            navigate("/login");
          }
        });
    } catch (error) {
      console.error("Error al decodificar el token:", error);
      toast.error("Error de autenticación", {
        position: "top-right",
        autoClose: 3000,
      });
      navigate("/login");
    }
  }, [userId, navigate]);

  const onChange = (e) => {
    setUserData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      const token = getToken();
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const res = await axios.post(
        `${baseAPI}/users/update/${userId}`, 
        userData,
        { headers }
      );
      
      if (userRole !== "admin" && res.data.token) {
        const tokenSet = setToken(res.data.token);
        if (!tokenSet) {
          toast.warning("No se pudo actualizar el token de sesión", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      }

      toast.success("¡Datos actualizados correctamente! 👍", {
        position: "top-right",
        autoClose: 2000,
      });

      navigate(0);
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.error(
        error.response?.data?.message || "Error al actualizar los datos. Por favor, inténtalo de nuevo.",
        {
          position: "top-right",
          autoClose: 3000,
        }
      );
      if (error.response?.status === 401) {
        navigate("/login");
      }
    } finally {
      setIsSaving(false);
    }
  };

  // Funciones para manejar templates por defecto
  const handleDefaultTemplateChange = (index, value) => {
    const newTemplates = [...userData.emailTemplates.default];
    newTemplates[index] = value;
    setUserData({
      ...userData,
      emailTemplates: {
        ...userData.emailTemplates,
        default: newTemplates
      }
    });
  };

  const addDefaultTemplate = () => {
    setUserData({
      ...userData,
      emailTemplates: {
        ...userData.emailTemplates,
        default: [...userData.emailTemplates.default, ""]
      }
    });
  };

  const removeDefaultTemplate = (index) => {
    const newTemplates = userData.emailTemplates.default.filter((_, i) => i !== index);
    setUserData({
      ...userData,
      emailTemplates: {
        ...userData.emailTemplates,
        default: newTemplates
      }
    });
  };

  // Funciones para manejar campos personalizados
  const handleCustomFieldChange = (fieldIndex, templateIndex, value, isFieldName = false) => {
    const newCustomFields = [...userData.emailTemplates.customFields];
    if (isFieldName) {
      newCustomFields[fieldIndex].fieldName = value;
    } else {
      newCustomFields[fieldIndex].templates[templateIndex] = value;
    }
    setUserData({
      ...userData,
      emailTemplates: {
        ...userData.emailTemplates,
        customFields: newCustomFields
      }
    });
  };

  const addCustomField = () => {
    if (newCustomField.fieldName.trim() === "") {
      toast.error("El nombre del campo no puede estar vacío", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    setUserData({
      ...userData,
      emailTemplates: {
        ...userData.emailTemplates,
        customFields: [...userData.emailTemplates.customFields, { ...newCustomField }]
      }
    });
    setNewCustomField({ fieldName: "", templates: [""] });
  };

  const removeCustomField = (index) => {
    const newCustomFields = userData.emailTemplates.customFields.filter((_, i) => i !== index);
    setUserData({
      ...userData,
      emailTemplates: {
        ...userData.emailTemplates,
        customFields: newCustomFields
      }
    });
  };

  const addTemplateToCustomField = (fieldIndex) => {
    const newCustomFields = [...userData.emailTemplates.customFields];
    newCustomFields[fieldIndex].templates.push("");
    setUserData({
      ...userData,
      emailTemplates: {
        ...userData.emailTemplates,
        customFields: newCustomFields
      }
    });
  };

  const removeTemplateFromCustomField = (fieldIndex, templateIndex) => {
    const newCustomFields = [...userData.emailTemplates.customFields];
    newCustomFields[fieldIndex].templates = newCustomFields[fieldIndex].templates.filter((_, i) => i !== templateIndex);
    setUserData({
      ...userData,
      emailTemplates: {
        ...userData.emailTemplates,
        customFields: newCustomFields
      }
    });
  };

  const userFields = [
    { id: "grid-username", label: "Nombre", name: "username", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-userlastname", label: "Apellido", name: "userlastname", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-usermail", label: "Email", name: "usermail", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-userphone", label: "Teléfono", name: "userphone", permissions: ["admin", "user", "client", "company"] },
  ];

  const invoiceFields = [
    { id: "grid-invoicename", label: "Nombre de facturación", name: "invoicedataname", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoiceaddress", label: "Dirección", name: "invoicedataaddress", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoicecity", label: "Ciudad", name: "invoicedatacity", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoicezip", label: "Código Postal", name: "invoicedatazip", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoicecountry", label: "País", name: "invoicedatacountry", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoicecif", label: "CIF/NIF", name: "invoicedatacif", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoiceemail", label: "Email de facturación", name: "invoicedataemail", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-judicialPercentage", label: "Porcentaje Judicial", name: "judicialPercentage", permissions: ["admin"] },
    { id: "grid-extrajudicialPercentage", label: "Porcentaje Extrajudicial", name: "extrajudicialPercentage", permissions: ["admin"] },
  ];

  const renderFields = (fields) => {
    if (!Array.isArray(fields)) return null;
    
    return fields
      .filter((field) => field.permissions.includes(userRole))
      .map((field) => (
        <div key={field.id} className="space-y-1.5">
          <label className="block text-sm font-medium text-slate-700" htmlFor={field.id}>
            {field.label}
          </label>
          <input
            className="block w-full px-3 py-2 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 sm:text-sm transition-colors duration-150"
            id={field.id}
            type="text"
            name={field.name}
            value={userData[field.name] || ""}
            onChange={onChange}
            disabled={isLoading || isSaving}
          />
        </div>
      ));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Personal Information */}
      <div className="bg-white rounded-xl border border-slate-200 overflow-hidden">
        <div className="px-6 py-4 border-b border-slate-200 bg-slate-50">
          <div className="flex items-center">
            <UserIcon className="h-5 w-5 text-blue-600 mr-2" />
            <h3 className="text-lg font-medium text-slate-900">Información Personal</h3>
          </div>
        </div>
        <div className="p-6">
          <form onSubmit={onSubmit}>
            <div className="grid gap-6 md:grid-cols-2">
              {renderFields(userFields)}
            </div>
          </form>
        </div>
      </div>

      {/* Billing Information */}
      <div className="bg-white rounded-xl border border-slate-200 overflow-hidden">
        <div className="px-6 py-4 border-b border-slate-200 bg-slate-50">
          <div className="flex items-center">
            <BuildingOfficeIcon className="h-5 w-5 text-blue-600 mr-2" />
            <h3 className="text-lg font-medium text-slate-900">Datos de Facturación</h3>
          </div>
        </div>
        <div className="p-6">
          <form onSubmit={onSubmit}>
            <div className="grid gap-6 md:grid-cols-2">
              {renderFields(invoiceFields)}
            </div>
          </form>
        </div>
      </div>

      {/* Email Templates Section - Only visible for admin */}
      {userRole === "admin" && (
        <div className="bg-white rounded-xl border border-slate-200 overflow-hidden">
          <div className="px-6 py-4 border-b border-slate-200 bg-slate-50">
            <div className="flex items-center">
              <EnvelopeIcon className="h-5 w-5 text-blue-600 mr-2" />
              <h3 className="text-lg font-medium text-slate-900">Plantillas de Email</h3>
            </div>
          </div>
          <div className="p-6">
            <form>
              {/* Default Templates */}
              <div className="mb-6">
                <div className="flex items-center justify-between mb-2">
                  <h4 className="text-sm font-medium text-slate-900">Plantillas por Defecto</h4>
                  <button
                    type="button"
                    onClick={addDefaultTemplate}
                    className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-700"
                  >
                    <PlusIcon className="h-4 w-4 mr-1" />
                    Añadir Plantilla
                  </button>
                </div>
                <div className="space-y-2">
                  {userData.emailTemplates.default.map((template, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <input
                        type="text"
                        className="flex-1 px-3 py-1.5 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-200 sm:text-sm transition-colors duration-150"
                        value={template}
                        onChange={(e) => handleDefaultTemplateChange(index, e.target.value)}
                        placeholder="Escribe tu plantilla aquí..."
                      />
                      <button
                        type="button"
                        onClick={() => removeDefaultTemplate(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Custom Fields */}
              <div>
                <div className="flex items-center justify-between mb-2">
                  <h4 className="text-sm font-medium text-slate-900">Campos Personalizados</h4>
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      value={newCustomField.fieldName}
                      onChange={(e) => setNewCustomField({ ...newCustomField, fieldName: e.target.value })}
                      placeholder="Nombre del campo"
                      className="px-3 py-1.5 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-200 sm:text-sm"
                    />
                    <button
                      type="button"
                      onClick={addCustomField}
                      className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-700"
                    >
                      <PlusIcon className="h-4 w-4 mr-1" />
                      Añadir Campo
                    </button>
                  </div>
                </div>

                <div className="space-y-4">
                  {userData.emailTemplates.customFields.map((field, fieldIndex) => (
                    <div key={fieldIndex} className="border border-slate-200 rounded-lg p-3">
                      <div className="flex items-center justify-between mb-2">
                        <input
                          type="text"
                          value={field.fieldName}
                          onChange={(e) => handleCustomFieldChange(fieldIndex, 0, e.target.value, true)}
                          className="text-sm font-medium text-slate-900 px-2 py-1 border-b border-transparent focus:border-blue-500 focus:outline-none"
                        />
                        <div className="flex items-center gap-2">
                          <button
                            type="button"
                            onClick={() => addTemplateToCustomField(fieldIndex)}
                            className="text-blue-600 hover:text-blue-700"
                          >
                            <PlusIcon className="h-4 w-4" />
                          </button>
                          <button
                            type="button"
                            onClick={() => removeCustomField(fieldIndex)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <TrashIcon className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                      <div className="space-y-2">
                        {field.templates.map((template, templateIndex) => (
                          <div key={templateIndex} className="flex items-center gap-2">
                            <input
                              type="text"
                              className="flex-1 px-3 py-1.5 rounded-lg border border-slate-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-200 sm:text-sm transition-colors duration-150"
                              value={template}
                              onChange={(e) => handleCustomFieldChange(fieldIndex, templateIndex, e.target.value)}
                              placeholder="Escribe tu plantilla aquí..."
                            />
                            <button
                              type="button"
                              onClick={() => removeTemplateFromCustomField(fieldIndex, templateIndex)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <TrashIcon className="h-4 w-4" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Common Save Button for all users */}
      <div className="bg-white rounded-xl border border-slate-200 overflow-hidden">
        <div className="p-6">
          <form onSubmit={onSubmit}>
            <div className="flex items-center justify-end gap-4">
              <div className="text-sm text-slate-600 flex items-center">
                <CheckCircleIcon className="h-5 w-5 text-emerald-500 mr-2" />
                Tus datos están protegidos
              </div>
              <button
                type="submit"
                disabled={isLoading || isSaving}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150"
              >
                {isSaving ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                    Guardando cambios...
                  </>
                ) : (
                  <>
                    <DocumentTextIcon className="h-4 w-4 mr-2" />
                    Guardar Cambios
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountPanel;
