import React, { useState } from "react";

function AnalyticsCard06({ estadosAgrupados }) {
  const [mostrarImporte, setMostrarImporte] = useState(false);

  
  // Iconos por estado
  // Se pueden añadir más iconos si se desea
  const iconosPorEstado = {
    "On going": "🔄",
    "Email opened": "📧",
    "Hot": "🔥",
    "Cold": "❄️",
    "Fallido": "⚠️",
    "Ilocalizable": "🚫",
    "Sin datos de contacto": "📭",
    "Cancelled": "❌",
    "Dispute": "⚔️",
    "Burofax Enviado": "📨",
    "Burofax con resultado negativo": "❌",
    "Burofax con resultado positivo": "✔️",
    "Demanda pendiente": "📅",
    "Demanda preparada": "📝",
    "Demanda presentada": "📄",
    "Demanda Admitida": "📑",
    "Demanda No Admitida": "⛔",
    "Oposición del Deudor": "✋",
    "Pendiente Requerimiento": "📩",
    "Requerimiento Judicial Positivo": "✔️",
    "Pendiente Ejecucion": "⏳",
    "Sentencia Estimatoria": "✅",
    "Sentencia Desestimatoria": "❌",
    "Sentencia Parcial": "⚖️",
    "Cobrado": "💰",
    "Cobrado Judicialmente": "⚖️"
};
  

  const toggleView = () => {
    setMostrarImporte(!mostrarImporte);
  };

  // Ordenar según el estado actual
  const estadosOrdenados = mostrarImporte
    ? [...estadosAgrupados].sort(
        (a, b) => b.totalImporteDeuda - a.totalImporteDeuda
      )
    : estadosAgrupados;

  // Encontrar el valor máximo para calcular el ancho proporcional
  const maxValor = mostrarImporte
    ? Math.max(...estadosOrdenados.map((e) => e.totalImporteDeuda))
    : Math.max(...estadosOrdenados.map((e) => e.cantidad));

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-sm rounded-xl border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <div className="flex items-center justify-between">
          <h2 className="font-semibold text-slate-800">Estado de Expedientes</h2>
          <button
            onClick={toggleView}
            className="inline-flex items-center justify-center px-3 py-1.5 text-sm font-medium text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors duration-150"
          >
            <svg className="w-4 h-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              {mostrarImporte ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              )}
            </svg>
            {mostrarImporte ? "Ver Cantidad" : "Ver Importe"}
          </button>
        </div>
      </header>

      <div className="grow p-5">
        <div className="flex flex-col h-full">
          <div className="grow">
            <div className="flex justify-between text-xs font-medium text-slate-500 uppercase tracking-wider mb-4">
              <span>Estado</span>
              <span>{mostrarImporte ? "Importe Total" : "Expedientes"}</span>
            </div>

            <div className="space-y-2">
              {estadosOrdenados.map(({ estado, cantidad, totalImporteDeuda }) => {
                const valor = mostrarImporte ? totalImporteDeuda : cantidad;
                const porcentaje = (valor / maxValor) * 100;
                
                return (
                  <div
                    key={estado}
                    className="relative bg-slate-50 rounded-lg p-3 hover:bg-slate-100 transition-colors duration-150"
                  >
                    <div className="relative z-10 flex justify-between items-center">
                      <div className="flex items-center min-w-0">
                        <span className="mr-2 text-lg">{iconosPorEstado[estado] || "❓"}</span>
                        <span className="text-sm font-medium text-slate-800 truncate">
                          {estado}
                        </span>
                      </div>
                      <div className="ml-2 text-sm font-semibold text-slate-800 whitespace-nowrap">
                        {mostrarImporte
                          ? `${totalImporteDeuda.toLocaleString("es-ES", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} €`
                          : cantidad}
                      </div>
                    </div>
                    <div
                      className="absolute inset-0 bg-blue-100/50 rounded-lg transition-all duration-200"
                      style={{ width: `${porcentaje}%` }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard06;
