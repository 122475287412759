import React, { useState } from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import InteractionsListComponent from "../partials/components/InteractionsList";

const InteractionsList = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const userToken = localStorage.getItem("token");

  if (userToken == null) {
    window.location = "/login";
  }

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Dashboard */}
            <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200">
              <header className="px-5 py-4 border-b border-slate-100">
                <h2 className="font-semibold text-slate-800">Interacciones</h2>
              </header>
              <InteractionsListComponent />
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  );
};

export default InteractionsList;
