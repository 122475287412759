import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import CurrencyFormat from "react-currency-format";
import { baseAPI } from "../../config";
import DashboardCard09 from "../components/DashboardCard09";
import AnalyticsCard06 from "../components/AnalyticsCard06";
import NewDebtChart from "../components/NewDebtChart";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import UserSelect from "./userSelectList";

const DataDashboard = () => {
  const [totals, setTotals] = useState({
    totalReclamaciones: 0,
    totalDeuda: 0,
    totalRecuperado: 0,
    totalPendiente: 0,
    importesRecuperadosPorDia: [],
    estadosAgrupados: [],
    porcentajeRecuperado: 0,
    reclamacionesActivas: 0,
    reclamacionesCerradas: 0,
    importePromedioDeuda: 0,
  });
  const [filterUser, setFilterUser] = useState("");
  const [decoded, setDecoded] = useState({});

  // Nuevos estados para las fechas (strings en formato YYYY-MM-DD)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Estado para mostrar/ocultar filtros
  const [showFilters, setShowFilters] = useState(false);

  const [expedientes, setExpedientes] = useState([]);

  const fetchData = useCallback(async () => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return;

    const decodedData = jwtDecode(userToken);
    setDecoded(decodedData);

    try {
      const params = {
        decoded: JSON.stringify(decodedData),
        filterUser,
      };

      if (startDate) {
        params.startDate = new Date(startDate).toISOString();
      }
      if (endDate) {
        params.endDate = new Date(endDate).toISOString();
      }

      const { data } = await axios.get(`${baseAPI}/expedients/dashboard`, {
        params,
      });
      setExpedientes(data);
      const calculatedTotals = calculateTotals(data);
      setTotals(calculatedTotals);
    } catch (error) {
      console.error(error);
    }
  }, [filterUser, startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const chartData = {
    labels: totals.importesRecuperadosPorDia.map((item) => item.fecha),
    datasets: [
      {
        label: "Importes Recuperados",
        data: totals.importesRecuperadosPorDia.map((item) => item.importe),
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };

  const calculateTotals = (expedientes) => {
    // Estados a excluir del cálculo de tasa de recobro
    const estadosExcluidosTasaRecobro = ['Dispute', 'Cancelled', 'Fallido', 'Sin datos de contacto'];

    // Filtrar expedientes excluyendo los estados que no deben contar para la tasa de recobro
    const expedientesParaTasaRecobro = expedientes.filter(
      exp => !estadosExcluidosTasaRecobro.includes(exp.estado)
    );

    const sumaLasDeudas = Math.round(
      expedientesParaTasaRecobro.reduce((acc, { importe_deuda }) => acc + importe_deuda, 0)
    );
    const filtraImportesRecuperados = expedientesParaTasaRecobro.flatMap(
      ({ importes_recuperados }) => importes_recuperados
    );

    const sumaImportesRecuperados = Math.round(
      filtraImportesRecuperados.reduce((acc, { importe }) => acc + importe, 0)
    );

    // Calcular reclamaciones activas y cerradas
    const estadosFinalizados = [
      'Cobrado',
      'Cobrado Judicialmente',
      'Cancelled',
      'Fallido',
      'Sentencia Desestimatoria',
      'Ilocalizable',
      'Sin datos de contacto',
      'Burofax con resultado negativo',
      'Demanda No Admitida'
    ];

    const estadosActivos = [
      'On going',
      'Email opened',
      'Hot',
      'Cold',
      'Dispute',
      'Burofax Enviado',
      'Burofax con resultado positivo',
      'Demanda pendiente',
      'Demanda preparada',
      'Demanda presentada',
      'Demanda Admitida',
      'Oposición del Deudor',
      'Pendiente Requerimiento',
      'Requerimiento Judicial Positivo',
      'Pendiente Ejecucion',
      'Sentencia Estimatoria',
      'Sentencia Parcial'
    ];

    const reclamacionesActivas = expedientes.filter(
      exp => estadosActivos.includes(exp.estado)
    ).length;

    const reclamacionesCerradas = expedientes.filter(
      exp => estadosFinalizados.includes(exp.estado)
    ).length;

    // Calcular porcentaje recuperado (ahora solo con expedientes válidos)
    const porcentajeRecuperado = sumaLasDeudas > 0 
      ? Math.round((sumaImportesRecuperados / sumaLasDeudas) * 100) 
      : 0;

    // Calcular importe promedio por deuda
    const importePromedioDeuda = expedientes.length > 0 
      ? Math.round(sumaLasDeudas / expedientes.length) 
      : 0;

    let agruparImportesRecuperadosPorDia = [];
    let importesPorDia = new Map();

    filtraImportesRecuperados.forEach((item) => {
      let fechaSinHora = new Date(item.fecha);
      fechaSinHora.setHours(0, 0, 0, 0);
      let key = fechaSinHora.toISOString().split("T")[0]; // Clave como YYYY-MM-DD

      if (!importesPorDia.has(key)) {
        importesPorDia.set(key, { fecha: key, importe: 0 });
      }
      let acumulado = importesPorDia.get(key);
      acumulado.importe += item.importe;
    });

    agruparImportesRecuperadosPorDia = Array.from(importesPorDia.values());

    // Ordenar por fecha
    agruparImportesRecuperadosPorDia.sort(
      (a, b) => new Date(a.fecha) - new Date(b.fecha)
    );

    let estadosAgrupados = [];
    let estados = new Map();
    expedientes.forEach((item) => {
      let key = item.estado;
      if (!estados.has(key)) {
        estados.set(key, { estado: key, cantidad: 0, totalImporteDeuda: 0 });
      }
      let acumulado = estados.get(key);
      acumulado.cantidad += 1;
      acumulado.totalImporteDeuda += item.importe_deuda; // Suma el importe de deuda al acumulado
    });
    estadosAgrupados = Array.from(estados.values());

    // Ordenar por cantidad
    estadosAgrupados.sort((a, b) => b.cantidad - a.cantidad);

    // Retorna los totales y los importes recuperados por día
    return {
      totalReclamaciones: expedientes.length,
      totalDeuda: sumaLasDeudas,
      totalRecuperado: sumaImportesRecuperados,
      totalPendiente: sumaLasDeudas - sumaImportesRecuperados,
      importesRecuperadosPorDia: agruparImportesRecuperadosPorDia,
      estadosAgrupados: estadosAgrupados,
      porcentajeRecuperado,
      reclamacionesActivas,
      reclamacionesCerradas,
      importePromedioDeuda,
    };
  };

  const { 
    totalReclamaciones, 
    totalDeuda, 
    totalRecuperado, 
    totalPendiente,
    porcentajeRecuperado,
    reclamacionesActivas,
    reclamacionesCerradas,
    importePromedioDeuda,
  } = totals;

  return (
    <div className="p-8 bg-slate-50 min-h-screen">
      {/* Header Section */}
      <div className="bg-white shadow-sm rounded-xl border border-slate-200 p-6 mb-8">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          {/* Title and Description */}
          <div className="mb-6 lg:mb-0">
            <h1 className="text-3xl font-extrabold text-slate-900 flex items-center gap-3">
              Bienvenido <span className="animate-wave inline-block">👋</span>
            </h1>
            <p className="text-slate-600 mt-2 text-lg">
              Monitoriza el estado de tu deuda en tiempo real de manera sencilla
              y efectiva.
            </p>
          </div>

          {/* Toggle Button for Filters */}
          <div className="w-full lg:w-auto">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center justify-center px-4 py-2.5 bg-blue-500 text-white rounded-lg text-sm font-medium hover:bg-blue-600 transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 w-full lg:w-auto"
            >
              {showFilters ? "Ocultar Filtros" : "Mostrar Filtros"}
              <span className="ml-2">
                {showFilters ? (
                  <ChevronUpIcon className="h-5 w-5 transition-transform duration-200" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 transition-transform duration-200" />
                )}
              </span>
            </button>
          </div>
        </div>

        {/* Filters Section - Conditionally Rendered */}
        {showFilters && (
          <div className="mt-6 pt-6 border-t border-slate-200">
            <div className="mb-4">
              <h3 className="text-lg font-medium text-slate-900">Filtros de búsqueda</h3>
              <p className="mt-1 text-sm text-slate-500">
                Ajusta los filtros para visualizar datos específicos
              </p>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-6">
              {/* Usuario Select - Ocupa 4 columnas en lg */}
              {decoded.role === "admin" && (
                <div className="lg:col-span-4">
                  <div className="bg-white p-4 rounded-lg border border-slate-200 shadow-sm">
                    <label className="block text-sm font-medium text-slate-700 mb-2">
                      Filtrar por Usuario
                    </label>
                    <UserSelect
                      selectedUser={filterUser}
                      onUserChange={(user) => setFilterUser(user)}
                    />
                    {filterUser && (
                      <button
                        onClick={() => setFilterUser("")}
                        className="mt-2 inline-flex items-center text-sm text-red-500 hover:text-red-700"
                      >
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        Limpiar selección
                      </button>
                    )}
                  </div>
                </div>
              )}

              {/* Grupo de Fechas - Ocupa 8 columnas en lg */}
              <div className="lg:col-span-8">
                <div className="bg-white p-4 rounded-lg border border-slate-200 shadow-sm">
                  <div className="flex items-center justify-between mb-4">
                    <label className="block text-sm font-medium text-slate-700">
                      Rango de Fechas de Creación
                    </label>
                    {(startDate || endDate) && (
                      <button
                        onClick={() => {
                          setStartDate("");
                          setEndDate("");
                        }}
                        className="inline-flex items-center text-sm text-red-500 hover:text-red-700"
                      >
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        Limpiar fechas
                      </button>
                    )}
                  </div>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label
                        className="block text-sm font-medium text-slate-600 mb-1"
                        htmlFor="startDate"
                      >
                        Fecha Inicio
                      </label>
                      <input
                        type="date"
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        max={endDate || undefined}
                        className="w-full px-4 py-2.5 border border-slate-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-150"
                      />
                    </div>

                    <div>
                      <label
                        className="block text-sm font-medium text-slate-600 mb-1"
                        htmlFor="endDate"
                      >
                        Fecha Fin
                      </label>
                      <input
                        type="date"
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={startDate || undefined}
                        className="w-full px-4 py-2.5 border border-slate-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-150"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Resumen de filtros activos */}
            {(filterUser || startDate || endDate) && (
              <div className="mt-4 flex flex-wrap gap-2">
                {filterUser && (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                    Usuario: {filterUser}
                  </span>
                )}
                {startDate && (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                    Desde: {new Date(startDate).toLocaleDateString()}
                  </span>
                )}
                {endDate && (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                    Hasta: {new Date(endDate).toLocaleDateString()}
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="space-y-8">
        {/* Primera fila de métricas principales */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <DashboardCard
            title="Total Reclamaciones"
            value={totalReclamaciones}
            colorClass="bg-blue-500"
            icon="📊"
          />
          <DashboardCard
            title="Total Deuda"
            value={totalDeuda}
            formatCurrency
            colorClass="bg-red-500"
            icon="💶"
          />
          <DashboardCard
            title="Total Recuperado"
            value={totalRecuperado}
            formatCurrency
            colorClass="bg-green-500"
            icon="💰"
          />
          <DashboardCard
            title="Deuda Pendiente"
            value={totalPendiente}
            formatCurrency
            colorClass="bg-yellow-500"
            icon="⏳"
          />
        </div>

        {/* Segunda fila de métricas adicionales */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <DashboardCard
            title="Tasa de Recuperación"
            value={porcentajeRecuperado}
            suffix="%"
            colorClass="bg-indigo-500"
            icon="📈"
          />
          <DashboardCard
            title="Reclamaciones Activas"
            value={reclamacionesActivas}
            colorClass="bg-emerald-500"
            icon="🔄"
          />
          <DashboardCard
            title="Reclamaciones Cerradas"
            value={reclamacionesCerradas}
            colorClass="bg-purple-500"
            icon="✅"
          />
          <DashboardCard
            title="Importe Promedio"
            value={importePromedioDeuda}
            formatCurrency
            colorClass="bg-cyan-500"
            icon="📝"
          />
        </div>

        <DashboardCard09 chartData={chartData} />
        {/* Nuevo gráfico de deuda por mes - Solo visible para admin */}
        {decoded.role === "admin" && (
          <NewDebtChart expedientes={expedientes} />
        )}
        <AnalyticsCard06 estadosAgrupados={totals.estadosAgrupados} />
      </div>
    </div>
  );
};

const DashboardCard = ({
  title,
  value,
  formatCurrency,
  colorClass = "bg-slate-500",
  icon,
  suffix,
}) => (
  <div className="bg-white shadow-sm rounded-xl border border-slate-200 p-6 hover:shadow-md transition-shadow duration-150">
    <header className="flex items-center justify-between mb-4">
      <h2 className="text-lg font-semibold text-slate-800">{title}</h2>
      <span className="text-2xl">{icon}</span>
    </header>
    <div className="flex items-center">
      <div
        className={`w-2 h-12 ${colorClass} rounded-full mr-4`}
        aria-hidden="true"
      ></div>
      <div className="text-2xl font-bold text-slate-800">
        {formatCurrency ? (
          <CurrencyFormat
            value={value}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            suffix={"€"}
          />
        ) : (
          <>
            {value}
            {suffix && <span className="ml-1">{suffix}</span>}
          </>
        )}
      </div>
    </div>
  </div>
);

export default DataDashboard;
