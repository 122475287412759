import React, { useState, useEffect } from 'react';
import MessagesBody from '../partials/messages/MessagesBody';
import MessagesFooter from '../partials/messages/MessagesFooter';
import { baseAPI } from '../config';
import { useParams } from "react-router-dom";
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';

function ChatDebtor() {
  const { id } = useParams();
  const [expedientId] = useState(id);
  const [historyChat, setHistoryChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [assistantTyping, setAssistantTyping] = useState(false);

  useEffect(() => {
    const fetchChatHistory = async () => {
      if (!expedientId) return;

      try {
        setLoading(true);
        const response = await fetch(`${baseAPI}/actions/chathistory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ expedientId }),
        });
        
        if (!response.ok) {
          throw new Error('Error al cargar el historial del chat');
        }

        const data = await response.json();
        setHistoryChat(data);
      } catch (error) {
        console.error('Error fetching chat history:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChatHistory();
  }, [expedientId]);

  const handleSendMessage = async (message) => {
    try {
      setLoading(true);
      const messageObject = {
        role: 'user',
        content: message,
        createdAt: new Date().toISOString(),
      };

      setHistoryChat(prev => [...prev, messageObject]);
      setAssistantTyping(true);
  
      const response = await fetch(`${baseAPI}/actions/new-chat-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ expedientId, userMessage: message }),
      });

      if (!response.ok) {
        throw new Error('Error al enviar el mensaje');
      }

      const newMessage = await response.json();
      
      // Simular delay natural en la respuesta
      const randomDelay = Math.floor(Math.random() * 1000) + 1000;
      setTimeout(() => {
        setAssistantTyping(false);
        setHistoryChat(prev => [...prev, newMessage]);
      }, randomDelay);

    } catch (error) {
      console.error('Error sending message:', error);
      setError('Error al enviar el mensaje');
      setAssistantTyping(false);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-slate-50 px-4">
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100 mb-4">
            <ChatBubbleLeftRightIcon className="w-8 h-8 text-red-600" />
          </div>
          <h2 className="text-2xl font-bold text-slate-800 mb-2">Error en el chat</h2>
          <p className="text-slate-600 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col bg-slate-50">
      <div className="flex-1 flex flex-col max-w-6xl mx-auto w-full shadow-xl bg-white">
        <header className="flex-none px-6 py-4 border-b border-slate-200">
          <div className="flex items-center space-x-3">
            <ChatBubbleLeftRightIcon className="w-6 h-6 text-blue-600" />
            <h1 className="text-xl font-semibold text-slate-800">Chat de Claim Up</h1>
          </div>
        </header>
        
        <div className="flex-1 overflow-y-auto">
          <MessagesBody 
            historyChat={historyChat}
            assistantTyping={assistantTyping}
          />
        </div>

        <MessagesFooter 
          onSendMessage={handleSendMessage}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ChatDebtor;