import React, { useState, useEffect } from "react";
// import axios from "axios";
import { jwtDecode } from "jwt-decode";
// import { baseAPI } from "../../config";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import checkOutCreateExpedient from "./StripePayout";

function CreateExpedient() {
  const [importe_deuda, setImporteDeuda] = useState(0);
  const [user_id, setUserId] = useState("");
  const [role, setRole] = useState("");
  const [deudor, setDeudor] = useState("");
  const [domicilio_deudor, setDomicilioDeudor] = useState("");
  const [ciudad_deudor, setCiudadDeudor] = useState("");
  const [cp_deudor, setCpDeudor] = useState("");
  const [telefono_deudor, setTelefonoDeudor] = useState("");
  const [mail_deudor, setMailDeudor] = useState("");
  const [tipo_deuda, setTipoDeuda] = useState("");
  const [tipo_reclamacion] = useState("Extrajudicial");
  const [plan, setPlan] = useState("");
  const [presupuesto, setPresupuesto] = useState(300);
  const [porcentajeBudget, setPorcentajeBudget] = useState(0);
  const [servicios, setServicios] = useState([]);

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const decoded = jwtDecode(userToken);
    const user_id = decoded.id;
    const userRole = decoded.role;
    setUserId(user_id);
    setRole(userRole);
    if (decoded.userDataComplete === false) {
      toast.error(
        "Por favor, completa tu perfil antes de crear una reclamación"
      );
      setTimeout(() => {
        window.location = `/profile/${user_id}`;
      }, 4000);
    }
  }, []);

  useEffect(() => {
    let servicios = [];
    let presupuesto = 300;
    let porcentajeBudget = 0;

    if (plan === "premium") {
      console.log("premium");
      presupuesto = 45;
      porcentajeBudget = 20;
      servicios = [
        {
          tipo: "burofax",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de burofax certificado al deudor",
          cantidad: 1,
          utilizados: 0,
        },
        {
          tipo: "llamada_robotizada",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de llamada robotizada",
          cantidad: 10,
          utilizados: 0,
        },
        {
          tipo: "sms",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de sms",
          cantidad: 20,
          utilizados: 0,
        },
        {
          tipo: "email",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de email",
          cantidad: 50,
          utilizados: 0,
        },
        {
          tipo: "llamada_personal",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de llamada personal",
          cantidad: 10,
          utilizados: 0,
        },
      ];
    }
    if (plan === "basic") {
      console.log("basic");
      presupuesto = 25;
      porcentajeBudget = 20;
      servicios = [
        {
          tipo: "llamada_robotizada",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de llamada robotizada",
          cantidad: 5,
          utilizados: 0,
        },
        {
          tipo: "sms",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 1,
          detalles: "Envío de sms",
          cantidad: 10,
          utilizados: 0,
        },
        {
          tipo: "email",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 1,
          detalles: "Envío de email",
          cantidad: 20,
          utilizados: 0,
        },
        {
          tipo: "llamada_personal",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de llamada personal",
          cantidad: 5,
          utilizados: 0,
        },
      ];
    }
    setPresupuesto(presupuesto);
    setPorcentajeBudget(porcentajeBudget);
    setServicios(servicios);
  }, [plan]);

  const onChangeDeudor = (e) => {
    setDeudor(e.target.value);
  };

  const onChangeDomicilioDeudor = (e) => {
    setDomicilioDeudor(e.target.value);
  };

  const onChangeCiudadDeudor = (e) => {
    setCiudadDeudor(e.target.value);
  };

  const onChangeCpDeudor = (e) => {
    setCpDeudor(e.target.value);
  };

  const onChangeTelefonoDeudor = (e) => {
    setTelefonoDeudor(e.target.value);
  };
  const onChangeMailDeudor = (e) => {
    setMailDeudor(e.target.value);
  };

  const onChangeImporteDeuda = (value) => {
    setImporteDeuda(value);
  };

  const onChangeTipoDeuda = (e) => {
    setTipoDeuda(e.target.value);
  };

  const onchangePlan = (e) => {
    setPlan(e.target.id);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const expedient = {
      user_id: user_id,
      deudor: deudor,
      telefono_deudor: telefono_deudor,
      mail_deudor: mail_deudor,
      domicilio_deudor: domicilio_deudor,
      ciudad_deudor: ciudad_deudor,
      cp_deudor: cp_deudor,
      importe_deuda: importe_deuda,
      tipo_reclamacion: tipo_reclamacion,
      tipo_deuda: tipo_deuda,
      presupuesto: presupuesto,
      porcentaje_presupuesto: porcentajeBudget,
      servicios: servicios,
    };

    if (
      expedient.tipo_deuda === "-Selecciona una opción-" ||
      expedient.presupuesto === 0 ||
      plan === ""
    ) {
      toast.error("Por favor, completa todos los campos");
    } else {
      checkOutCreateExpedient(presupuesto, expedient, role);
    }
  };

  return (
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <div className="flex items-center">
          <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <h2 className="font-semibold text-slate-800">Crear Expediente de Reclamación</h2>
        </div>
      </header>

      <div className="p-6">
        <form className="space-y-8" onSubmit={onSubmit}>
          {/* Sección Información de la Deuda */}
          <section>
            <div className="flex items-center mb-6">
              <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 className="text-lg font-semibold text-slate-800">Información de la Deuda</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Cantidad a reclamar
                </label>
                <CurrencyFormat
                  className="form-input w-full text-slate-800 px-3 py-2 border border-slate-200 rounded-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="0 €"
                  thousandSeparator="."
                  suffix=" €"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  onValueChange={(values) => {
                    const { value } = values;
                    onChangeImporteDeuda(parseFloat(value));
                  }}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Tipo de deuda
                </label>
                <select
                  className="form-select w-full text-slate-800 px-3 py-2 border border-slate-200 rounded-sm focus:border-blue-500 focus:ring-blue-500"
                  onChange={onChangeTipoDeuda}
                  required
                  defaultValue="-Selecciona una opción-"
                >
                  <option>-Selecciona una opción-</option>
                  <option>Factura</option>
                  <option>Reconocimiento de deuda</option>
                  <option>Préstamo personal sin garantía</option>
                  <option>Préstamo hipotecario</option>
                  <option>Pagaré</option>
                  <option>Tarjeta de crédito</option>
                  <option>Tarjeta revolving</option>
                  <option>Impago suscripción recurrente</option>
                  <option>Contrato de arrendamiento</option>
                  <option>Otro tipo de deuda</option>
                </select>
              </div>
            </div>
          </section>

          {/* Sección Información del Deudor */}
          <section>
            <div className="flex items-center mb-6">
              <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              <h3 className="text-lg font-semibold text-slate-800">Información del Deudor Principal</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Nombre del Deudor
                </label>
                <input
                  type="text"
                  className="form-input w-full text-slate-800 px-3 py-2 border border-slate-200 rounded-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Nombre completo"
                  onChange={onChangeDeudor}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Dirección
                </label>
                <input
                  type="text"
                  className="form-input w-full text-slate-800 px-3 py-2 border border-slate-200 rounded-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Calle y número"
                  onChange={onChangeDomicilioDeudor}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Ciudad
                </label>
                <input
                  type="text"
                  className="form-input w-full text-slate-800 px-3 py-2 border border-slate-200 rounded-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Ciudad"
                  onChange={onChangeCiudadDeudor}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Código Postal
                </label>
                <input
                  type="text"
                  className="form-input w-full text-slate-800 px-3 py-2 border border-slate-200 rounded-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Código Postal"
                  onChange={onChangeCpDeudor}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  className="form-input w-full text-slate-800 px-3 py-2 border border-slate-200 rounded-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="email@ejemplo.com"
                  onChange={onChangeMailDeudor}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-2">
                  Teléfono
                </label>
                <input
                  type="tel"
                  className="form-input w-full text-slate-800 px-3 py-2 border border-slate-200 rounded-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="+34 XXX XXX XXX"
                  onChange={onChangeTelefonoDeudor}
                  required
                />
              </div>
            </div>
          </section>

          {/* Sección Plan de Reclamación */}
          <section>
            <div className="flex items-center mb-6">
              <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
              </svg>
              <h3 className="text-lg font-semibold text-slate-800">Plan de Reclamación</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Plan Basic */}
              <label className={`block cursor-pointer bg-white rounded-sm border ${
                plan === "basic" ? "border-blue-500 ring-2 ring-blue-200" : "border-slate-200"
              } hover:border-slate-300 shadow-sm transition duration-150 ease-in-out`}>
                <input
                  type="radio"
                  name="plan"
                  id="basic"
                  className="sr-only"
                  onChange={onchangePlan}
                  required
                />
                <div className="p-4">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center">
                      <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span className="font-semibold text-slate-800">Plan Basic</span>
                    </div>
                    {plan === "basic" && (
                      <svg className="w-5 h-5 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    )}
                  </div>
                  <ul className="space-y-2 text-sm text-slate-600 mb-4">
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Llamadas robotizadas: 5 intentos
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      SMS: 10 mensajes
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Emails certificados: 20 correos
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Llamadas directas: 5 llamadas
                    </li>
                  </ul>
                  <div className="text-center bg-gradient-to-r from-emerald-500 to-emerald-700 text-white px-3 py-2 rounded-sm text-sm font-medium">
                    25€ + IVA y 20% del importe recuperado
                  </div>
                </div>
              </label>

              {/* Plan Premium */}
              <label className={`block cursor-pointer bg-white rounded-sm border ${
                plan === "premium" ? "border-blue-500 ring-2 ring-blue-200" : "border-slate-200"
              } hover:border-slate-300 shadow-sm transition duration-150 ease-in-out`}>
                <input
                  type="radio"
                  name="plan"
                  id="premium"
                  className="sr-only"
                  onChange={onchangePlan}
                  required
                />
                <div className="p-4">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center">
                      <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                      </svg>
                      <span className="font-semibold text-slate-800">Plan Premium</span>
                    </div>
                    {plan === "premium" && (
                      <svg className="w-5 h-5 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    )}
                  </div>
                  <ul className="space-y-2 text-sm text-slate-600 mb-4">
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Burofax: 1 certificado
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Llamadas robotizadas: 10 intentos
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      SMS: 20 mensajes
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Emails certificados: 50 correos
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 mr-2 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      Llamadas directas: 10 llamadas
                    </li>
                  </ul>
                  <div className="text-center bg-gradient-to-r from-emerald-500 to-emerald-700 text-white px-3 py-2 rounded-sm text-sm font-medium">
                    45€ + IVA y 20% del importe recuperado
                  </div>
                </div>
              </label>
            </div>
          </section>

          <div className="mt-8">
            <button
              type="submit"
              className="w-full sm:w-auto inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-150"
            >
              <svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              Confirmar encargo
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateExpedient;
